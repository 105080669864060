const messages = {
  "header.home": "HOME",
  "header.products": "PRODUCTS",
  "header.technology": "TECHNOLOGY",
  "header.about.us": "ABOUT US",
  "header.careers": "CAREERS",
  "header.contact.us": "CONTACT US",
  "header.language": "LANGUAGE",
  english: "ENGLISH",
  hindi: "HINDI",
  signin: "Sign In",
  "signing.in": "Signing In",
  signup: "Start Free Trial",
  home: "Home",
  features: "Features",
  pricing: "Pricing",
  whyus:"Why Us",
  contact: "Contact Us",
  solutions: "Solutions",
  "products.smartstore.eazy": "SMARTSTORE EAZY",
  "products.smartstore.enterprise": "SMARTSTORE ENTERPRISE",
  "products.smartproduct.locator": "SMART PRODUCT LOCATOR",
  "products.smartpayment.gateway": "SMART PAYMENT GATEWAY",
  "products.smartpoint.sale": "SMART POINT OF SALE",
  "products.grocery.express": "GROCERY EXPRESS",
  "smartstore.eazy.heading": "Key Features",
  "smartstore.eazy.selling.online":
    "Everything you need to start selling online",
  "smartstore.eazy.unique.store": "Get your unique online store",
  "smartstore.eazy.feature.standout":
    "Stand-out in the crowd by creating your unique wesbite",
  "smartstore.eazy.feature.domain":
    "Use your own domain name and increase your brand value",
  "smartstore.eazy.feature.colors":
    "Choose colors and themes that reflect your brand",
  "smartstore.eazy.feature.banners":
    "Enhance your website by uploading attractive banners",
  "smartstore.eazy.setup.shop": "Set up your shop easily",
  "smartstore.eazy.create.own":
    "Create your own shop - No technical skills required",
  "smartstore.eazy.feature.setup.online":
    "Use our step by step guide to easily set-up your online shop",
  "smartstore.eazy.feature.text.boxes":
    "Make all changes using simple text boxes, drop-downs and drag & drop menus",
  "smartstore.eazy.feature.catalogue":
    "Create your catalogue, set prices, set shipping rules and start selling online",
  "smartstore.eazy.feature.mobile": "Go mobile instantly",
  "smartstore.eazy.additional.sales":
    "Get additional sales by selling on mobile devices",
  "smartstore.eazy.selling.mobile":
    "Start selling on mobile instantly as your website will look<br /> even more stunning on mobile devices",
  "smartstore.eazy.shopping.convenience":
    "Enable shopping convenience as shopping cart will be common across devices",
  "smartstore.eazy.international.app":
    "Get your own international quality Android and iOS app",
  "smartstore.eazy.manage.go": "Manage it on-the-go",
  "smartstore.eazy.easy.access":
    "Easily access your shop-admin on your Android mobile",
  "smartstore.eazy.update.prices": "Update prices, stock and images",
  "smartstore.eazy.important.alerts":
    "Get alerts on important events such as order placement, payment and cancellation",
  "smartstore.eazy.customer.reply":
    "Reply to customer emails and chats without any delay",
  "smartstore.eazy.customer.reachout": "Reach out to your customers",
  "smartstore.eazy.powerful.seo": "Get new customers with our powerful SEO",
  "smartstore.eazy.offers.coupons":
    "Create offers and coupons targeting a segment of your customers",
  "smartstore.eazy.marketing.campaigns":
    "Run targeted marketing campaigns via SMS, Emails and App-notifications to specific customers",
  "smartstore.eazy.manage.customers": "Manage your customers",
  "smartstore.eazy.build.loyalty": "Use our powerful CRM to build loyalty",
  "smartstore.eazy.view.customers":
    "View all your customers in one place with their detailed purchase history",
  "smartstore.eazy.upload.details":
    "Upload details of your offline-customers to provide them the same level of service",
  "smartstore.eazy.add.edit.details":
    "Add or edit details such as address, email and phone number of your customers",
  message404:
    "You didn’t break the internet, but we can’t find what you are looking for.",
  "go.home": "Go to homepage",
  "zopping.pricing": "Pricing Plan",
  "choose.pricing": "Choose your pricing plan",
  "why.choose.us":"Why Choose Us",
  "pay.for.use": "Pay us only for what you sell online through us",
  "currency.selector": "Currency Selector",
  "everything.free": "Everything is FREE including Desktop & Mobile Web",
  "android.app.customers": "Android App for customers",
  "ios.app.customers": "iOS App for Customers",
  "picker.app.employees": "Picker App for Employess",
  "logistics.app.employees": "Logistic App for Employess",
  "buy.now": "Buy Now",
  "contact.us.support": "Contact Us for the support",
  "contact.us.new.support": "Contact Sales",
  "customization.integration": "Reach to us for customization and integration",
  "customization.new.integration": "Reach to us for a quotation",
  "cant.find.answer": "Can’t find an answer?",
  "pricing.faq": "Pricing - Frequently asked questions",
  "view.more": "View More",
  "view.less": "View Less",
  "more.than": "More than",
  "month.sales": "month sales",
  "upto.text": "Upto",
  heroHeadingText: "Grow your business online",
  heroParagraphText: "The ultimate E-commerce Platform for Retailers.",
  heroFormButtonText: "Start Selling Online for Free",
  heroFormInputPlaceholder: "Email address or Phone number",
  heroFormPhoneNumberPlaceholder: "Phone number",
  heroFormEmailIdPlaceholder: "Email address",
  "email.two.days": "Email within two working days",
  "email.instant.chat": "Email & Instant Chat as soon as possible",
  "email.and.telephonic":
    "Email, Instant Chat, & Telephonic as soon as possible",
  "email.account.manager":
    "Email, Instant Chat, Telephonic & Account Manager will be assigned",
  testimonialsHeading: "Our customers love what we do",
  testimonialsSubHeading:
    "Our customers send us bunch of smiles with our services and we love them",
  "select.language.heading": "Select language",
  "my.dashboard": "My Dashboard",
  "logout.text": "Logout",
  "privacy.policy": "Privacy Policy",
  "pricing.comparison.heading": "Why to choose us?",
  "pricing.comparison.subheading": "See our feature comparison with others",
  "compare.with.others": "Compare with others",
  customDomain: "Custom Domain",
  onlineStore: "Online Store",
  catalogue: "Catalogue",
  payments: "Payments",
  marketingOffers: "Marketing & Offers",
  orderManagement: "Order Management",
  ratingsReviews: "Ratings & Reviews",
  support: "Support via emails, chats & phone calls",
  salesChannels: "Sales Channels",
  orderCreation: "Manual order creation",
  discounts: "Product discount & coupon discounts",
  ssl: "SSL Certificate",
  cart: "Abandoned cart",
  giftCards: "Gift cards",
  reportsAnalytics: "Reports & Analytics",
  yes: "Yes",
  no: "No",
  limited: "Limited",
  unlimited: "Unlimited",
  hostingSsl: "Hosting and SSL certificate",
  themes: "Themes",
  customerSignup: "Customer Signup Page",
  multiStore: "Multi Stores",
  multiSales: "Multi Sales Channel",
  products: "Products",
  collectionTags: "Collections & Tags",
  weightBasedPrices: "Weight-based Prices",
  productSubstitution: "Product Substitutions",
  stockManagement: "Stock management",
  rackManagement: "Rack management",
  gatewayInteg: "Gateway Integration",
  walletCashback: "Wallets and Cashbacks",
  cashOnDelivery: "Cash on Delivery",
  voucherGifts: "Vouchers/ Gift Cards",
  campaigns: "Campaigns (Email/ SMS)",
  notifications: "App Notifications",
  customSeo: "Custom SEO",
  coupons: "Coupons",
  offers: "Offers on Products/ Collections/ Tags",
  manualOrder: "Manual Order Creation",
  pickerApp: "Picker Mobile App",
  intelligentPicking: "Intelligent Picking Queue",
  deliveryManagement: "Delivery Management",
  surcharge: "Surcharge",
  slotCharges: "Slot-based charges",
  vehiclePlanning: "Vehicle Planning",
  routePlanning: "Route Planning",
  deliveryPersonTracking: "Delivery Person Tracking",
  thirdPartyLogistics: "Third-Party Logistics Integration",
  staffManagement: "Staff Management",
  multiUsers: "Multi-Users",
  employeeMobileApp: "Employee Mobile App",
  attendanceSummary: "Attendance Summary",
  roleBasedPermissions: "Role-Based Permissions",
  reportAnalytics: "Reports & Analytics",
  reports: "Reports",
  analytics: "Analytics",
  monthlyFixedCharges: "Monthly Fixed Charges",
  commissions: "Commissions",
  freePlugins: "Free Plugins",
  customerMobileApp: "Customer mobile app (iOS and Android)",
  "contact.help.banner.question": "How can we help you?",
  "contact.help.banner.tagline":
    "Just leave your contact details below and our e-commerce expert will get in touch with you at the earliest",
  "contact.placeholder.fullname": "Full Name",
  "contact.placeholder.phone": "Phone number",
  "contact.placeholder.email": "Email address",
  "contact.placeholder.message": "Message",
  "contact.reachout": "Reach out to us for a quick response",
  "contact.reachout.question": "Got a question? Get in touch with us",
  "contact.chat": "Chat with us",
  "map.message": "We are right here",
  "map.viewmap": "View in map",
  "zopping.welcome": "Welcome to Zopping",
  "zopping.loginForm.subheading": "Enter your details below",
  "loginForm.username.placeholder": "Enter your email or phone number",
  "loginForm.email.id.placeholder": "Enter your email",
  "feature.one.description":
    "Get started for free and pay only for what you use",
  "feature.two.description":
    "All the features and apps completely unlocked at no extra cost",
  "feature.three.description":
    "A variable monthly charge that follows your monthly online sales",
    "feature.one.new.description":
    "Best in the industry customer support",
    "feature.two.new.description":
    "A platform that scales as your business grows",
    "feature.three.new.description":
    "Highly customizable and open to 3rd party integrations",
  "monthly.charges": "Monthly Charges",
  "pricing.rule": "Montly Online Store Sales",
  "highlight.heading": "What you get",
  "monthly.store.sales": "Monthly Online Store Sales",
  "activation.charge": "Activation Charges",
  "charges.per.month": "Charges per month",
  "setup.store.free": "Setup your store for FREE!",
  password: "Password",
  "loginForm.password.placeholder": "Enter your password",
  "remember.me": "Remember me",
  "dont.have.account": "Don't have an account",
  "create.account": "Create an account",
  name: "Name",
  "enter.name": "Enter your name",
  "create.your.account": "Create your account",
  "creating.account": "Creating your account",
  "already.have.account": "Already have an account",
  "forgot.password": "Forgot Password?",
  "submit.text": "Submit",
  submitting: "Submitting",
  "resetPassword.successMessage": "Your password has been successfully reset",
  "resetPassword.loginLinkText": "Go to login",
  "can.try.zopping": "Can I try Zopping without paying?",
  "sign.up.free.trial":
    "Yes. Just sign up and avail 15 days free trial of the platform.",
  "enter.credit.card":
    "Do I need to enter my credit card number or any other payment details to take the trial?",
  "no.requirement.payment.details":
    "No. There is no requirement to enter any payment details to take the trial. You can just sign-up and start using the platform",
  "trial.over": "What happens once my trial is over?",
  "continue.using.platform":
    "If you want to continue using the platform, then you will have to pay by recharging your in-built wallet with a minimum of Rs.2,000. In case you don’t recharge your wallet within 15 days of trial expiry, your account will get permanently deleted.",
  "how.do.i.pay": "How do I pay?",
  "can.pay.by":
    "We automatically deduct the money every month from your account wallet based on the orders fulfilled through your online store for that particular month. You can store and recharge money into your ",
  pay: "Pay",
  "pay.once": "Pay once,",
  "use.forever": "use forever",
  "pricing.feature.less.than.text1":"Less than 2%",
  "pricing.feature.less.than.text2":"Less than 0.25%",
  "pricing.feature.chargeable":"Chargeable",
  "free.trail": "7 DAYS FREE TRIAL",
  "what.is.activation.charge.question": "What is the Activation Charge?",
  "what.is.activation.charge.answer":
    "Activation Charge is a one-time charge levied by Zopping for creating your online store. However, we offer a 7-day free trial during which time you can build and use your online store without paying any Activation Charge.",
  "what.happens.free.trail.end.question":
    "What happens to my online store after my free trial period ends and I don’t pay the Activation Charge?",
  "need.maintenance.charge.running.store.question":
    "Do I need to pay any monthly/ yearly maintenance charge to keep my online store running?",
  "account.wallet": "account wallet",
  "need.maintenance.charge.running.store.answer":
    "Yes. Apart from the one-time Activation Charge, you will have to pay a monthly maintenance fee, which is dependent on the amount of sales made on your online store. You can refer to the above slab to understand your monthly maintenance charges.",
  "what.happens.free.trail.end.answer":
    "If you do not pay the Activation Charge before the trial period ends, then your store will be disabled. However, all the details and data about your online store will remain saved and secure. You can pay the Activation Charge and get back to using your online store at any time in the future from the point where you left.",
  "how.do.pay.charge.question": "How do I pay the Activation Charge?",
  "how.do.pay.charge.answer":
    "Once you login to your Zopping dashboard, you will notice a button on top of the Home page to pay the Activation Charge. You can click on it and proceed to pay.",
  "how.do.pay.monthly.maintenance.charge.question":
    "How do I pay the monthly maintenance charge?",
  "how.do.pay.monthly.maintenance.charge.answer1":
    "On completion of a calendar month, we send you an email with the billing amount based on the value of orders you won on the online store. This amount will be automatically deducted from your account wallet. You can store and recharge money into your   ",
  "how.do.pay.monthly.maintenance.charge.answer2":
    "from your admin panel or the app. Our payment gateway accepts all standard credit cards, debit cards, bank transfers, UPI and e-wallets.",
  "my.account.balance.insufficient.question":
    "What happens when my account wallet balance is insufficient to pay the monthly charges?",
  "my.account.balance.insufficient.answer":
    "We alert you through multiple channels like email, push notifications and SMS about the insufficient funds in your wallet and request you to recharge your wallet.",
  "my.account.balance.insufficient.answer.case1":
    "Case 1: If the wallet balance is negative for more than 15 days continuously then your account will be automatically disabled i.e. your website will be down. As soon as you recharge your wallet and make the balance positive, your website will be live again.",
  "my.account.balance.insufficient.answer.case2":
    "Case 2: If the wallet balance is negative for more than 60 days continuously then the account will be automatically deleted i.e. both your website and admin account will be deleted. All your data will be permanently lost.",
  "need.to.bear.in.mind.question":
    "Are there any other costs that I need to bear in my mind?",
  "need.to.bear.in.mind.answer1":
    "If you wish to publish your Android or iOS app, then we charge a nominal fee. ",
  "need.to.bear.in.mind.answer2":
    "Setup and usage of Zopping Picker and Delivery apps are also charged. Please reach out to your sales team for the quotation.  ",
  "need.to.bear.in.mind.answer3":
    "Apart from these, all the other features, themes, extensions and apps are completely free. However, if you choose your own domain name, then you may have to pay your domain provider. Delivery charges and payment gateway charges as agreed between you and the concerned parties are not included in our pricing plan and hence has to be borne by you.Have more doubts? Feel free to contact us by Email or call.",
  "charge.admin.panel":
    "from your admin panel or the app. Our payment gateway accepts all standard credit cards, debit cards, bank transfers, UPI and e-wallets.",
  "features.during.trial": "What features do I get during trial?",
  "features.paid.user":
    "You get all the features that a paid user gets. To see the complete list please visit Zopping ",
  "features.page": "features page",
  "addon.features": "What are add-on features?",
  "addon.features.answer":
    "Add-on features are functionalities that are specific to individual businesses and may not be needed by everyone. You can view all these functionalities and turn-on as per your need.",
  "addon.features.charge": "Are add-on features chargeable?",
  "addon.features.charge.answer":
    "Most of the add-on features are free of charge and you just need to enable them to start using them. For paid add-on features, the payment is deducted from your account wallet as per the pricing of the individual feature.",
  "turnoff.addon.features": "Can I turn-off add-on features anytime?",
  "turnoff.addon.features.answer":
    "Yes. But you need to be extremely careful while turning-off these features as you may lose their corresponding data permanently.",
  "remind.balance.low":
    "Will you remind me if my account wallet balance is low?",
  "remind.balance.low.answer":
    "Yes. We will send you multiple reminders via sms and email once your account balance is below Rs.2000.",
  "negative.balance.wallet":
    "What happens when my account wallet balance is insufficient to pay the monthly charges?",
  "negative.balance.reminder":
    "We alert you through multiple channels like email, push notifications and SMS about the insufficient funds in your wallet and request you to recharge your wallet.",
  "negative.balance.case.one":
    "Case 1: If the wallet balance is negative for more than 15 days continuously then your account will be automatically disabled i.e. your website will be down, although you will be able to access your account-admin. As soon as you recharge your wallet and make the balance positive, your website will be live again.",
  "negative.balance.case.two":
    "Case 2: If the wallet balance is negative for more than 60 days continuously then the account will be automatically deleted i.e. both your website and admin account will be deleted. All your data will be permanently lost.",
  "stop.using.platform":
    "If I want to stop using your platform, will I get my wallet-money back?",
  "stop.using.platform.answer":
    "Yes. At any time you can communicate to us to that you want to stop using our platform. We will refund your wallet amount after deduction of the running month’s rental payment and dues till date.",
  install: "Install",
  installing: "Installing",
  uninstall: "Uninstall",
  uninstalling: "Uninstalling",
  installed: "Installed",
  "registered.user.text": "You are already registered",
  "zopping.features": "Zopping Features",
  "feature.description":
    "Get a better understanding of all the amazing things Zopping can do.",
  "login.or.text": "OR",
  "login.with.google": "Login with Google",
  "signup.with.google": "Sign up with Google",
  free: "Free",
  customizable: "Customizable",
  standard: "Standard",
  premium: "Premium",
  "stay.in.touch": "Stay in touch",
  "contact.us": "Contact Us",
  "about.us": "About Us",
  "company.text": "Company",
  "blog.text": "Blog",
  "share.this.article":
    "<span style='  font-size: 20px; color: #777E83; line-height: 22px;'>SHARE THIS ARTICLE</span>",
  "posted.on": "Posted On",
  "read.text": "read",
  "instagram.text": "Instagram",
  "facebook.text": "Facebook",
  "twitter.text": "Twitter",
  "linkedin.text": "Linkedin",
  "you.might.also.like.reading": "You might also like Reading",
  "terms.conditions": "Terms and Conditions",
  "terms.conditions.about-1": `The terms Raramuri Consultancy Services LLP individually and
  collectively refer to the Company and the terms "Visitor” ”User”
  refer to the users.`,
  "terms.conditions.about-2": `This page states the Terms and Conditions under which you (Visitor)
  may visit this website (“www.zopping.com”). Please read this page
  carefully. If you do not accept the Terms and Conditions stated
  here, we would request you to exit this site. The business, any of
  its business divisions and / or its subsidiaries, associate
  companies or subsidiaries to subsidiaries or such other investment
  companies (in India or abroad) reserve their respective rights to
  revise these Terms and Conditions at any time by updating this
  posting. You should visit this page periodically to re-appraise
  yourself of the Terms and Conditions, because they are binding on
  all users of this Website.`,
  "terms.conditions.minimumAge": "Minimum Age Requirements",
  "terms.conditions.minimumAge.about":
    "Our Services are not directed to children. You’re not allowed to access or use our Services if you’re under the age of 18 (or 16 in Europe). If you register as a user or otherwise use our Services, you represent that you’re at least 18 (or 16 in Europe). You may use our Services only if you can legally form a binding contract with us. In other words, if you’re under 18 years of age (or the legal age of majority where you live), you can only use our Services under the supervision of a parent or legal guardian who agrees to the Agreement.",
  "terms.conditions.misuse": "Misuse",
  "terms.conditions.misuse.about":
    "The sellers may list and provide the details of the products/ services offered for sale to the buyers as a registered user of the Platform, however the sellers shall not be permitted to sell any product that falls under the list of banned items identified by the Company from time to time, or are otherwise prohibited for sale under applicable Law.",
  "terms.conditions.liability": "Limited Liability",
  "terms.conditions.liability-1":
    "A. The Company shall not be responsible for the sale of products listed by the users on the website and app created using its Platform.",
  "terms.conditions.liability-2": `B. Any products or services that are listed for sale using our
    platform by its sellers is the sole responsibility of the sellers.
    You must look solely to the seller for any damages that result
    from your purchase or use of the products or services listed`,
  "terms.conditions.liability-3": `C. We may limit your access to our Services until we're able to
  verify your account information, like your email address or phone
  number.`,
  "terms.conditions.refund": `Refund and Cancellation Policy`,
  "terms.conditions.refund-1": `A. If the user decides to close their account and recover any unused account wallet money, they may do so by writing to us on support@zopping.com. We will refund the unused money after deducting any outstanding dues and taxes.`,
  "terms.conditions.refund-2": `B. For electronic payments, refunds shall be made through payment
  facility using any other online banking / electronic funds
  transfer system approved by Reserve Bank India (RBI).`,
  "terms.conditions.refund-3": `C. Refunds may be supported for select banks. Where a bank is not
  supported for processing refunds, You will be required to share
  alternate bank account details with for processing the refund.`,
  "terms.conditions.refund-4": `D. The bank will take 5-7 working days to refund the amount.`,

  "hidden.costs": "Are there any hidden costs?",
  "hidden.costs.answer":
    "No. There aren’t any. All the features, themes, extensions and apps are completely free. However, if you choose your own domain name, then you may have to pay your domain provider. Delivery charges and payment gateway charges as agreed between you and the concerned parties are not included in our pricing plan and hence has to be borne by you.",
  "cost.online.store": "How much does it cost to set up my online store?",
  "cost.online.store.answer":
    "It is completely free to set up your online store. You don’t need to pay us anything until you hit a minimum of Rs. 20,000 or USD 1,000 (based on your billing slab) worth of orders per month through your online store. From there, the charges are based on the pricing plan mentioned above. Since your monthly sales through your online store vary every month, so are your monthly charges.",
  "highlight.hosting": "Hosting (Zopping subdomain) and SSL certificate",
  "highlight.themes": "Fully customizable themes",
  "highlight.product.listing": "Unlimited product listings",
  "highlight.unlimited.users": "Unlimited users",
  "highlight.unlimited.operations": "Unlimited multi-store operations",
  "highlight.apps": "Consumer app*, picker app, delivery app, admin app",
  "highlight.extensions": "All plugins/ extensions",
  "highlight.gateway.integrations": "Payment gateway integrations",
  "highlight.delivery.partner": "Delivery partner integrations",
  "highlight.social.media": "Social media and Google API integrations",
  "highlight.customer.support": "24x7 customer support",
  "less.than": "Less than",
  "email.error.msg": "Please enter a valid email address. Eg-abc@xyz.com",
  "anyone.can.sell.anything": "Anyone Can Sell Anything Online!",
  "brands.retailers.resellers":
    "From brands to retailers to resellers to online-first entrepreneurs anybody can set up and run an",
  "span.mobile.app": "Mobile App ",
  "span.online.store": "Online Store ",
  "and.a.text": "and a ",
  "matter.few.minutes": "in a matter of few minutes",
  "setup.free.online.store": "Setup your FREE online store",
  "schedule.demo": "Schedule a DEMO",
  "zero.web.dev.skills": "Zero Web Development Skills Needed",
  "span.list.text": "List ",
  "span.receive.text": "Receive ",
  "span.manage.text": "Manage ",
  "products.text": "products, ",
  "orders.text": "orders, ",
  "payments.handle.deliveries":
    "payments, handle deliveries, promote your brand and grow online easily using our intutive and customizable interface.",
  "your.store.customers.rules":
    "Your store, Your customers, Your margins, Your rules",
  "experience.online.marketplaces":
    "Frustrated by the experience of selling through online marketplaces like Amazon, Flipkart, Udaan, etc? With your own online store and mobile app, you get to control your brand and customer experience, keep your hard earned margins and customer data completely with yourself.",
  "explore.our.features": "Explore our Features",
  "platform.specific.business":
    "A Platform Built for the Specific Nature of Your Business",
  "our.host.extensions": "Our host of extensions allow you to ",
  "span.customize.text": "Customize ",
  "your.online.store": "your online store while our ",
  "span.enterprise.technology.text": "ENTERPRISE-GRADE TECHNOLOGY ",
  "handle.thousands.transactions":
    "seamlesly handles thousands of transactions in a day and scale up your business.",
  "explore.prices": "Explore Prices",
  "start.online.store": "Start your Online store with few clicks",
  "sell.groceries.medicines":
    "Sell groceries, medicines, furniture, books, electronics, beauty products, jewellery, clothes, handicrafts, bakery products or anything online.",
  "store.information": "Store Information",
  "enter.store.name.logo": "Enter your Store name and logo",
  "select.domain": "Select Domain",
  "choose.store.url": "Choose your store URL",
  "select.industry": "Select Industry",
  "pick.business.operate": "Pick the business you operate",
  "select.theme": "Select Theme",
  "choose.theme.liking": "Choose the theme or colours of your liking",
  "download.now": "Download now",
  "unlock.ecommerce.power":
    "Unlock the power of E-commerce with the Zopping app",
  "features.banner.one.description":
    "Setting up and running your online store made super easy",
  "features.banner.two.description":
    "Advanced customizations made possible through a host of ‘installable’ extensions",
  "features.banner.three.description":
    "Access all the features via the Zopping mobile app",
  hosting: "Hosting",
  "hosting.desc":
    "Get free @zopping.com domain with free hosting when you sign up. You can move to your own domain immediately or at a later date.",
  "ssl.desc":
    "We offer a free 256-bit SSL certificate for your domain to enable a safe and secure shopping experience for your customers.",
  "themes.desc":
    "Choose from our library of templates that match the nature of your business. Customize page layouts, menus and colours, add logos, favicons, static pages and blogs to make your store reflect your brand. Easily switch between themes without breaking your website.",
  "search.box": "Search box",
  "search.box.desc":
    "Enable your customers to search for products from your store using our intelligent and personalised search functionality. Tailor and fix the search results for specific search terms to promote a particular product/ category/ brand.",
  "multi.language": "Multi-language",
  "multi.language.desc":
    "Set languages that you wish to support on your eStore and allow your customer to browse and shop on your eStore in the language they prefer.",
  "product.listing": "Product Listing",
  "product.listing.desc":
    "Upload and easily group your products into categories and multi-tier sub-categories. Add images, descriptions, tags, stock, prices, discounts, tax rates, cess and much more using built-in custom fields. Conveniently upload or edit your products individually or in bulk.",
  "sell.by.brand": "Sell by Brand",
  "items.sold.by-weight": "Sold by Weight",
  "sell.by.brand.desc":
    "Add a ‘brand’ field to your products on the catalogue to enable your customers to search and filter products based on the brand name.",
  "items.sold.by.weight": "Items Sold-by-Weight",
  "items.sold.by-weight.desc":
    "Start selling loose items and sold-by-weight such as fruits,vegetables, grains, meat, metals, etc.",
  "product.variants": "Product Variants",
  "product.variants.desc":
    "Seamlessly handle products that have variations by colour, size, weight, etc. Update photos, prices and discounts for each variant.",
  "product.subsitution": "Product Substitution",
  "product.subsitution.desc":
    "Set substitutes for your products and improve your fill rate by sending substitute products in case the originally ordered product is unavailable.",
  "stock.management.desc":
    "Upload or download stock data, set up buffer stock and get alerts when any product goes out of stock.",
  "payment.gateway.3rd.party": "3rd-party Payment Gateway Integration",
  "payment.gateway.3rd.party.desc":
    "Take advantage of a whole host ofpre-configured 3rd party payment gateways to quickly set up your payment mechanism. Intelligently automate your payment routing through multiple gateways to optimize your merchant transaction charges.",
  "paypal.integration": "PayPal Integration",
  "paypal.integration.desc":
    "Accept international orders and payments with our in-built PayPal integration.",
  "your.ewallet": "Your eWallet",
  "your.ewallet.desc":
    "Improve your customer loyalty and reward customers by offering them your branded eWallet. Your customers can add money to their wallets and use it during their future purchases.",
  "gift.cards.desc":
    "Enable your customers to gift their loved ones by selling customizable e-Gift Cards which can only be redeemed in your store.",
  cod: "Cash on Delivery (COD)",
  "cod.desc": "Offer COD functionality to your customers",
  "webpages.and.banners": "Webpages and Banners",
  "webpages.and.banners.desc":
    "Create and schedule custom web pages and web banners for different pages of your website to promote new product launches, offers, seasonal and festive sales, etc",
  "offers.management": "Offers Management",
  "offers.management.desc":
    "Create, customize, run and track 10+ variants of offers (like Flat off/ % off/ Min. Purchase/ combos/ Buy-One-Get-One/ % extra) on products/ categories/ brands/ customer segments. Set your limits and rules for the applicability of an offer.",
  "referral.management": "Referral Management",
  "referral.management.desc":
    "Grow your customers by creating a customised referral program that rewards your loyal customers and new users.",
  "google.merchant.center": "Google Merchant Center",
  "google.merchant.center.desc":
    "Integrate your Google Merchant Center account with your Zopping account and automatically sync your Zopping catalogue to Google Merchant Center with the simple click of a button.",
  "reviews.ratings": "Reviews and Ratings",
  "reviews.ratings.desc":
    "Enhance the credibility of your online store by providing your customers an option to rate and review your products and orders.",
  blogs: "Blogs",
  "blogs.desc":
    "Build an online community of loyal customers and readers by writing blogs that answer important questions for your customers or potential customers. Blogging for your eCommerce store can also has a positive impact on your your SEO ranking.",
  "campaign.management": "Campaign Management",
  "campaign.management.desc":
    "Create, schedule, run and monitor promotional campaigns through App notifications, Emails and SMSes to your customers. Distribute coupons, announce product launches, alert price drops, etc. Use the in-built customer segmentation tool to run targeted campaigns.",
  "coupons.desc":
    "Generate and distribute unique or standard coupons to offer product or shipping discounts to your customers. Set discount limits and restrict the applicability of the coupon based on the order value/ payment options/ order day/ customer segment/ store. Measure and monitor the success of the distribution of coupons and the resulting sales.",
  seo: "Search Engine Optimization (SEO)",
  "seo.desc":
    "Get discovered by your customers when they search for you or your products on the internet. Set your page titles, descriptions and keywords to appear higher in Google searches.",
  "orders.dashboard": "Orders Dashboard",
  "orders.dashboard.desc":
    "An intuitive and easy-to-navigate dashboard to view all your orders in one place with complete details such as order number, customer name, contact details, order time, order status, payment status, order value, etc.",
  "orders.alerts": "Order Alerts",
  "orders.alerts.desc":
    "Stay on top of your customers’ orders by getting instant SMS/ push notifications on important events such as order placement, cancellation, delivery, etc.",
  "delivery.area.management": "Delivery area management",
  "delivery.area.management.desc":
    "Restrict the areas to which you can deliver based on the pincodes or distance from the store or by drawing on the map.",
  "delivery.partner.integration": "Delivery Partner Integration",
  "delivery.partner.integration.desc":
    "Deliver your customer orders on your own or choose from our list of delivery partners to ship within your city or anywhere in India.",
  "customer.details": "Customer details",
  "customer.details.desc":
    "All your customer data and their purchase history are available in one place for you to edit, download, search or group. Import your existing customer data and keep everything in one place.",
  "live.chat": "Live chat",
  "live.chat.desc":
    "Resolve your customers’ queries through the live chat widget while they are shopping in your store.",
  "order.returns": "Order Returns",
  "order.returns.desc":
    "Seamlessly manage order returns, automatically adjust your inventory and refund your customers",
  "multi.user.access": "Multi-user Access",
  "multi.user.access.desc":
    "Allow your employees to manage your store. Set roles and permissions. Manage shifts and attendance.",
  "multi.store": "Multi-store",
  "multi.store.desc":
    "Have more than one physical store? We let you manage prices, offers, delivery charges based",
  "reports.desc":
    "Download standard sales, marketing, operations, customer, stock reports or create your custom report.",
  "analytics.desc":
    "Use our dashboard to track the health of your business. Monitor and compare your sales, marketing campaigns, operations, orders, customer growth, stock to derive meaningful insights and business intelligence.",
  "fb.analytics": "Facebook Analytics",
  "fb.analytics.desc":
    "Easily integrate and track the performance of your Facebook ads using your Facebook pixel ID.",
  "google.analytics": "Google Analytics",
  "google.analytics.desc":
    "Easily integrate your Google Analytics with your eStore to track your customer acquisition channels, demographics, revenue and other rich insights.",
  "customer.app": "Customer App",
  "customer.app.desc":
    "A customized and branded iOS and Android customer app for your store. Set your app name, launch icon and splash screens to reflect your brand.",
  "delivery.app": "Delivery App",
  "delivery.app.desc":
    "An Android app for your delivery staff to pick, prioritize and deliver the orders.",
  "picker.app": "Picker App",
  "picker.app.desc":
    "An Android app for your staff to pick, pack and verify orders, and print labels.",
  "admin.app": "Admin App",
  "admin.app.desc":
    "A free iOS and Android app for you to manage your online store on your mobile. Track your sales, review orders, modify your products, run marketing campaigns and change your store settings on the go.",
  "custom.password.format": "Customer password format setup",
  "custom.password.format.desc":
    "Set custom rules that your customers should follow while creating a password thereby improving the security.",
  "data.security": "Data Security",
  "data.security.desc": "Your data is accessible only to you.",
  "data.and.security": "Data and Security",
  apps: "Apps",
  channels: "Channels",
  "customer.management": "Customer Management",
  delivery: "Delivery",
  marketing: "Marketing",
  cataloguing: "Cataloguing",
  "testimonials.ambros":
    "I was completely new to e-commerce and scared about the last-mile delivery operations. I couldn’t have asked for a better or easier solution than the Zopping e-commerce platform. The entire process from order placement on web/mobiles apps through last-mile delivery is easy and extremely efficient. A fantastic experience overall! Amazing solution for retailers at an affordable price from the Zopping team!",
  "testimonials.medinmin":
    "I recommend Zopping e-com platform to everyone looking for web & app development. They have plug-and-play functionalities that meet your requirements immediately. They not only fulfilled my expectations but also exceeded them. The web & mobile apps are very attractive & also offer superb engagement with the users. I am really impressed by their service, software and professionalism.",
  "testimonials.blossom":
    "Zopping offered us the right guidance when we required an application to take our books business online. Before I decided, we had a discussion with their sales team and it guided us on how to convert our idea into an online book store. Later, the onboarding team was in touch with us throughout the set-up process and ensured that they customize the website as per our needs. Thanks a ton, Zopping team!",
  "testimonials.name.ambros": "Rohit Kumar",
  "testimonials.designation.ambros": "Director",
  "testimonials.company.ambros": "Ambros Retail",
  "testimonials.name.medinmin": "Irfan B",
  "testimonials.designation.medinmin": "Ops Manager",
  "testimonials.company.medinmin": "Medinminutes",
  "testimonials.name.blossom": "Mayigowda",
  "testimonials.designation.blossom": "Managing Director",
  "testimonials.company.blossom": "Blossom Book House",
  "give.zopping.try": "Give Zopping a try",

  "custom.domain.title":
    "How to set up a custom domain (URL) for your online store in Zopping?",
  "custom.domain.description":
    "As a Zopping customer, we offer a free zopping subdomain (URL) and SSL certificate for your online store.",
  "custom.domain.more.descriptions.1":
    "For example, if your store name is '<strong style='color:black;'>XYZ Supermart</strong>', then we automatically create an online store with a URL of <span style='color:#587BE7;'>https://xyzsupertmart.zopping.com</span> for your customers to access your catalogue and place orders.<br><br>However, you might want to change this domain (URL) with your own to brand your website. This guide will help you link your custom domain with the online store that is created using Zopping.<br><br>To successfully switch your custom domain, you will need to make changes in two places and in the order mentioned below:<br><br>1. On your Domain Registrar (website from which you bought your domain) account <br>2. Zopping Dashboard",
  "custom.domain.heading.title":
    "Changes to be made in your Domain Registrar account - Updating DNS records:",
  "custom.domain.para.1.description":
    "1. Login to your Domain Registrar account (example: GoDaddy, Google Domains, Domain.com, Hover, etc).<br>2 .Go to Manage DNS or the equivalent setting.<br>3. Add a <b style='color:black'>CNAME</b> record with the following details:",
  "custom.domain.point.1.description": "Host Name (prefix of your domain)",
  "custom.domain.point.2.description": "Content - stores.zopping.com",
  "custom.domain.point.3.description":
    "TTL (enter 600 secs or whichever is the minimum value supported by your domain registrar)",
  "custom.domain.refrence.heading":
    "<br><br><b style=font-size:20px;>Reference links/ guides to adding DNS records for popular domain registrar services:</b></br></br>",
  "custom-domain-page.2.description":
    "Once the above-mentioned changes are done, it might take a few minutes or hours for the changes to reflect globally depending on your Domain Registrar and traffic.<br><br><b>TIP :</b>You can check if the DNS records of your domain have been updated or not, by visiting  <a style='text-decoration: underline;color:#587BE7;'href='https://www.whatismyip.com/dns-lookup/'target='_blank'>WhatIsMyIP</a><br><br>Once the IP address has been successfully updated and started pointing to <b style='color: black;'>35.232.68.105</b>,then proceed to further steps.",
  "custom-domain-setup-heading-2":
    "Changes to be made in the Zopping Dashboard",
  "custom-domain-setup-description-3":
    " 1. Sign in to your Zopping Dashboard either from your browser or the Zopping App<br>2. Navigate to<b style='color: black;'> Settings</b><br>3. Click on <b style='color: black;'>Basic Information</b><br>4. Under <b style='color: black;'>Store URL</b>, choose <b style='color: black;'>I have a custom domain</b><br>5. In the Store URL box, enter the domain name to which you wish to have your online store redirected to<br>6. Click on<b style='color: black;'> Save</b>",
  "custom-domain-setup-description-4":
    " <b style='font-size: 18px;'>NOTE:</b><br>For your custom domain to redirect to the online store created with Zopping, you need to make changes <b style='color: black;'>both</b> on the Domain Registrar account and Zopping dashboard and in the right order<br><br>For any additional help, please contact us on <b style='color: #00BE00;'> +91 9916814808</b> or drop an email at <b style='color: #00BE00;'>support@zopping.com</b>",

  "five.reasons.title": "5 reasons to have your own online store",
  "five.reasons.description":
    "With the penetration of the internet, there is a rapid shift in the buying pattern of consumers. The Covid-19 pandemic has further accelerated this transition. A <a href='https://www.mckinsey.com/business-functions/marketing-and-sales/our-insights/the-great-consumer-shift-ten-charts-that-show-how-us-shopping-behavior-is-changing' target='_blank' rel='noopener noreferrer'> study </a> conducted by McKinsey on US consumer buying behaviour states there has been a 15-30% growth in consumers who purchase online with groceries, apparel, furniture and medicines categories leading the pack. This shift poses a significant threat to traditional retailers or distributors who have been selling offline all along. But this also presents an opportunity to digitally transform their business and stay relevant in the changing times.",
  "five.reasons.list1.description":
    "Here are some of the top reasons why it is important for you as a retailer/ reseller/ social-media entrepreneur to set up your own online store:",
  "five.reasons.list1.items.1.title": "Ease of shopping",
  "five.reasons.list1.items.1.description":
    "A lot of retailers still rely on taking orders over WhatsApp or a phone call. This often results in a poor customer experience whereby the customer is unable to fully explore or search the catalogue, compare products or view product details. But by using your online store you can list, promote, track and sell better thereby improving your revenue and customer retention.",
  "five.reasons.list1.items.2.title": "Better margins",
  "five.reasons.list1.items.2.description":
    "In trying to catch up with the digital boom, few retailers are migrating to online marketplaces like Amazon, Flipkart, Udaan, etc. However, a lot of them end up having bitter experiences due to the buyer-friendly return policies and high commissions charged by the online marketplaces. By owning an online store, you need not share your revenues with 3rd parties and hence improve your margins.",
  "five.reasons.list1.items.3.title": "Build Customer Loyalty",
  "five.reasons.list1.items.3.description":
    "Having an own online store means you now have access to your customer data and their transaction history. You can use this valuable information to run loyalty programs by offering discounts and cashbacks or run email or SMS marketing campaigns to improve your customer retention and lifetime value of your customers.",
  "five.reasons.list1.items.4.title": "Reach anywhere",
  "five.reasons.list1.items.4.description":
    "Small and medium businesses have done well in using the power of social media to brand and attract leads. However, if the leads from your marketing activity are not properly channelled to a page to make a purchase it can create leaks in your sales funnel. This is where owning an online store closes the loop. By integrating your online store with 3rd party logistics can enable you to reach customers anywhere in the world. Thus turning your local business into a global one.",
  "five.reasons.list1.items.5.title": "Business continuity",
  "five.reasons.list1.items.5.description":
    "One thing that the pandemic has exposed us to is the fragility and uncertainty associated with people's lives and businesses. With people being stuck in their homes, offline retailers have overnight lost their customers and they, unfortunately, didn’t have a Plan B. With viral variants getting formed very frequently, Covid induced lockdowns will become a norm. Hence it is important to shield your business from this uncertainty by having an online presence.",
  "five.reasons.paragraph.one":
    "Setting up an online store by a retailer can be quite challenging due to the significant amount of money, time and technical skills required. Not all retailers have the know-how of web development and it can quickly get overwhelming and confusing.",
  "five.reasons.paragraph.two":
    "Luckily, there are new-age, no-code tools like Zopping which enable retailers to set up their own online store in a matter of few clicks even though one doesn’t have any web development skills. The intuitive, click, drag-drop functionality makes it a no-brainer to set up and run your store.",

  "top.five.features.title":
    "Top 5 features to look for while choosing your Online Store Builder",
  "top.five.features.description":
    "In a flourishing era of no-code tools, setting up an online store has never been this easier for someone with little to no understanding of web development. Popular tools like Zopping, WooCommerce, Shopify, Squarespace, Dukaan, etc are giving business owners a wide range of options to choose from. However, there are a few things to keep in mind when choosing your online store builder as the cost of switching can be steep once you settle with one tool.",
  "top.five.features.list1.items.1.title": "Ease of use",
  "top.five.features.list1.items.1.description":
    "Even though tools like Shopify claim to be an online store builder, one runs into quick trouble as any tiny bit of customization will require the help of programmers. If you are someone who doesn't want to hire web developers and want to do it on your own, then you should consider tools that offer you drag-and-drop functionality to quickly navigate and customize your website. The tasks could be as simple as listing your products, adding banners, setting up loyalty programs, integrating payment gateways and delivery partners, you should be able to do all these on your own using simple drag-drop functionalities.",
  "top.five.features.list1.items.2.title": "Customization",
  "top.five.features.list1.items.2.description":
    "Lot of online store builders out there in the market have limited customization options. This means your store is most probably going to look like every other store on the internet. You should choose tools that will actually help you customize and match the way your business truly runs. You might want to restrict your delivery areas, charge different customers differently, promote a specific brand, create a unique product listing page or offer buying suggestions. This is where a one-size-fits-all online builder can disappoint you. Your online store builder should match with the way your business runs and not the other way around.",
  "top.five.features.list1.items.3.title": "Payment options",
  "top.five.features.list1.items.3.description":
    "Gone are the days when customers preferred to pay with cash. The flourishing space of digital payments ranging from netbanking, credit/ debit cards, UPI, eWallets, Buy-Now-Pay-Later (BNPL) options offer customers a lot of choices to pay for their goods or services. To stay on top of the change you should look for online store builders that offer simple plug-and-play payment gateway integrations.",
  "top.five.features.list1.items.4.title": "Mobile App",
  "top.five.features.list1.items.4.description":
    "One of the trends in the eCommerce space that we are increasingly seeing is that customers are making purchases through their smartphones or tablets. Hence it is important to not only have an eCommerce website but also a mobile-friendly website and a mobile app! At Zopping, we not only offer to our customers a mobile-friendly online store but also free Android and iOS mobile apps which the store owners can immediately host on their PlayStore or App Store accounts. This means, our customers in a matter of a few clicks can get their online store running on both web and mobile apps.",
  "top.five.features.list1.items.5.title": "Pricing Plan",
  "top.five.features.list1.items.5.description":
    "While choosing an online builder it is not sufficient to look at upfront set up cost alone but you should also have a rough estimate of the operating expenses that you would incur. Lots of online store builders in the market charge customers a certain percentage of commission on the amount of sales conducted through their online store. These commissions can range anywhere between 2-5%. This can quickly eat into your profits. There are also several add-on charges that get levied, including, owning a custom domain, plugins charges, mobile app generation charges, etc. At Zopping, we have taken a completely different approach towards charging our customers. There are no add-on charges. We charge a variable amount every month and the variable charges depend on the sales you make through your online store. If you had a bad month, you don’t need to pay us anything. In good months, we charge a very reasonable price. This way you don’t need to incur fixed costs but keep your online store running always - just the way you intend it to be.",

  "how.to.setup.title": "How to set up your own online store using Zopping",
  "how.to.setup.description":
    "Setting up your online store using Zopping is super easy. We at Zopping have made the entire platform intuitive so that you can build, manage and grow your dream online business with a few simple clicks.",
  "how.to.setup.more.descriptions.1":
    "To set up an online store, you need to first create an account on Zopping. You can register either using your email address or phone number or simply sign-up with Google. Once you have registered, you need to choose a name for your store, a logo and select a theme from the numerous options offered.After you finish these steps, we automatically generate an URL and an online store. However, in most cases, this won’t be sufficient for you to start selling your products to your customers. Following is a list of a few bare minimum settings that you need to configure to be able to start selling.",
  "how.to.setup.list1.items.1.title": "Customize Appearance",
  "how.to.setup.list1.items.1.description":
    "To host your online store, we offer a free Zopping subdomain and SSL certificate. However, to make your store stand out and build trust among your customers, it is recommended that you choose your own domain. If you don’t own any domain, you can buy it online from stores like GoDaddy. Once you have your domain, configure your domain on the Basic Information Page and do not forget to Set A record in your DNS settings with 34.122.113.56 as the value. You should also set your store logo and favicon, which can go a long way in creating a sense of genuineness among your customers.",
  "how.to.setup.list1.items.2.title": "List products",
  "how.to.setup.list1.items.2.description":
    "Add all the products that you would like to sell through your online store. Group them into categories and sub-categories. The <a href='/admin/catalogue/products/add' target='_blank' rel='noopener noreferrer'>listing page</a> also allows you to add product descriptions, images, variants (different sizes, weights, colours, lengths, etc) and substitutes, set prices, discounts and stock limits, and assign brands and tags so that your users can easily navigate, discover and buy your products. If you want to add additional details, you can always add <a href='/admin/settings/extensions/18' target='_blank' rel='noopener noreferrer'>custom fields</a> to your product listing!",
  "how.to.setup.list1.items.3.title": "Delivery Settings",
  "how.to.setup.list1.items.3.description":
    "Apart from the customers picking up the orders at your store, do you also offer home delivery? If yes, to which locations? What is the minimum order value to be eligible for home delivery? And what are the delivery charges? Do you have a 3rd party delivery partner? These are the settings that you configure by visiting the <a href='/admin/online-store/order-config' target='_blank' rel='noopener noreferrer'>Order Config page</a>",
  "how.to.setup.list1.items.4.title": "Select payment methods",
  "how.to.setup.list1.items.4.description":
    "You have an option to receive payments from your customers through several popular payment gateways. Head over to the  <a href='/admin/settings/payment-configuration' target='_blank' rel='noopener noreferrer'>Payments page</a>, choose your payment gateway provider and fill in the credentials to instantly enable the online payment collection method on your website. You can also choose to enable or disable Cash-On-Delivery (COD) option from the <a href='/admin/online-store/order-config' target='_blank' rel='noopener noreferrer'>Order Config Page</a>",
  "how.to.setup.list1.items.5.title": "Trial run",
  "how.to.setup.list1.items.5.description":
    "Once you have set up everything, don’t forget to conduct a trial run by placing a sample order and getting it delivered to the desired location so that you are sure that you have your operation aligned and running smoothly.",
  "how.to.setup.para.1.description":
    "That is all, your online store is now ready. Easy-peasy! Now start sharing your online store on your marketing channels, display them on flyers and posters or run email or SMS campaigns using Zopping to start receiving orders. Happy selling!",
  "how.to.setup.para.2.description":
    "Still have questions? Feel free to <a href='/contact' target='_blank' rel='noopener noreferrer'>contact us</a>.",

  "create.discount.coupons.zopping.title":
    "How to create discount coupons in Zopping?",
  "create.discount.coupons.zopping.description":
    "Coupons are a useful way for you to encourage your customers to buy more often. They can also be shared with an affiliate marketer or influencer to get more reach for your website.",

  "create-discount-coupons-zopping-para1":
    "Coupons are a useful way for you to <span style='color: #000'>encourage your customers </span> to buy more often. They can also be shared with an affiliate marketer or influencer to get more reach for your website. Once shared, you will also be able to track the effectiveness of the affiliate or the influencer based on the number of times the coupon has been used by your customers while making a purchase.",
  "create-discount-coupons-zopping-para2":
    "With Zopping, we allow you to create coupons whose terms and conditions can be easily configured by you.",

  "create-discount-coupons-zopping-question1": "Steps to create a coupon?",
  "create-discount-coupons-zopping-question1-step-heading1": "Step 1",
  "create-discount-coupons-zopping-question1-step1":
    "To create a coupon, click on the ‘ <span style='color: #000'>Coupons </span>’ menu",
  "create-discount-coupons-zopping-question1-step2":
    "Click on the ‘ <span style='color: #000'>+ Add Coupons</span>’ button",
  "create-discount-coupons-zopping-question1-step-heading2": "Step 2",
  "create-discount-coupons-zopping-question1-step3":
    "Enter the coupon name, its validity and other<br> restrictions.The below guide will tell what each of the fields means.",
  "create-discount-coupons-zopping-question1-step-heading3": "Step 3",
  "create-discount-coupons-zopping-question1-step4":
    "Click on ‘ <span style='color: #000'>Submit</span>’ to create the coupon. The newly <br>created coupon will be visible on your Coupons page",
  "create-discount-coupons-zopping-question1-sub-heading1":
    "Explanation of fields in the Add Coupon page:",
  "create-discount-coupons-zopping-question1-sub-heading1-point1":
    "Coupon Code:",
  "create-discount-coupons-zopping-question1-sub-heading1-point1-body":
    "This is the coupon code that you would be creating, which the customer will use at the time of purchase to avail the discount. Note that the coupon code is case-sensitive.",
  "create-discount-coupons-zopping-question1-sub-heading1-point2": "Validity:",
  "create-discount-coupons-zopping-question1-sub-heading1-point2-body":
    "Here you can define the time period during which the coupon will be valid.",
  "create-discount-coupons-zopping-question1-sub-heading1-point3":
    "Coupon gives:",
  "create-discount-coupons-zopping-question1-sub-heading1-point3-body":
    "This allows you to offer a flat discount or a percentage discount of the order value.",
  "create-discount-coupons-zopping-question1-sub-heading1-point4":
    "Coupon Applicable for:",
  "create-discount-coupons-zopping-question1-sub-heading1-point4-body":
    "This allows your coupon to offer a discount either on the order value or the shipping cost.",
  "create-discount-coupons-zopping-question1-sub-heading1-point5": "Comment:",
  "create-discount-coupons-zopping-question1-sub-heading1-point5-body":
    "This is only for your internal reference purpose. You can mention your comments and these won’t be visible to your customers.",
  "create-discount-coupons-zopping-question1-sub-heading1-point6":
    "Max. redemption by single user:",
  "create-discount-coupons-zopping-question1-sub-heading1-point6-body":
    "This field sets how many times this particular coupon code can be used by a customer. If nothing is mentioned, then it is considered that there is no limit on the number of times a customer can use this coupon.",
  "create-discount-coupons-zopping-question1-sub-heading1-point7":
    "Max. redemption:",
  "create-discount-coupons-zopping-question1-sub-heading1-point7-body":
    "This field allows you to restrict how many times this particular coupon can be used overall - combining all your customers. If nothing is mentioned, then it is considered that there is no limit on the number of times this coupon can be applied.",
  "create-discount-coupons-zopping-question1-sub-heading1-point8":
    "Max. discount amount:",
  "create-discount-coupons-zopping-question1-sub-heading1-point8-body":
    "This limits the amount of discount that a user can avail while using this coupon. This is particularly useful if you are creating a coupon that offers a percentage discount on either order or shipping value. Configuring this field limits the discount you offer to your customers.",
  "create-discount-coupons-zopping-question1-sub-heading1-point9":
    "Minimum order amount:",
  "create-discount-coupons-zopping-question1-sub-heading1-point9-body":
    "You can set a minimum order amount to be able to use this coupon",
  "create-discount-coupons-zopping-question1-sub-heading1-point10":
    "Payment Mode:",
  "create-discount-coupons-zopping-question1-sub-heading1-point10-body":
    "The created coupon can be restricted to function only for specific payment modes - either for Cash on Delivery (COD), Online or both",
  "create-discount-coupons-zopping-question1-sub-heading1-point11":
    "Visibility:",
  "create-discount-coupons-zopping-question1-sub-heading1-point11-body":
    "When you enable the ‘Private’ checkbox, then the coupon is not visible to users on the checkout page. If it is not check-marked, then the coupon will be visible on the checkout page of your website to all customers upon clicking the ‘View all coupons’ link",
  "create-discount-coupons-zopping-question1-sub-heading1-point12": "Days:",
  "create-discount-coupons-zopping-question1-sub-heading1-point12-body":
    "The coupon will only work on the days specified days marked here. You can use this restriction to offer coupon discounts to your customers on lean days.",
  "create-discount-coupons-zopping-question1-sub-heading1-point13":
    "Applies to:",
  "create-discount-coupons-zopping-question1-sub-heading1-point13-body":
    "The coupon will only work for orders placed in the chosen stores",
  "create-discount-coupons-zopping-question1-sub-heading1-point14":
    "Description:",
  "create-discount-coupons-zopping-question1-sub-heading1-point14-body":
    "If your coupon is not marked ‘Private’ then the description of the coupon mentioned here will be visible to your customers",
  "create-discount-coupons-zopping-question1-sub-heading1-point15": "Image:",
  "create-discount-coupons-zopping-question1-sub-heading1-point15-body":
    "If your coupon is not marked ‘Private’ then the image of the coupon uploaded here will be visible to your customers",
  "create-discount-coupons-zopping-question1-sub-heading1-point16": "Segments:",
  "create-discount-coupons-zopping-question1-sub-heading1-point16-body":
    "Here, you can choose the set of customers who can use this coupon. If nothing is selected, it is assumed that the coupon applies to all customers.",

  "create-discount-coupons-zopping-question2":
    "How to disable or enable a coupon?",
  "create-discount-coupons-zopping-question2-answer":
    "Once created, you cannot delete the coupon. It can only be edited or disabled. To disable a coupon, click on the ‘Coupons’ menu. Click on the more icon ( ) and select the ‘Disable’ option to disable a coupon.",

  "create-discount-coupons-zopping-question3":
    "Where can I find my coupon analytics?",
  "create-discount-coupons-zopping-question3-answer":
    "To view the analytics of a coupon, click on the ‘Coupons’ menu. Click on the more icon ( ) and select the ‘Analytics’ option to view the coupon analytics.",

  "create-discount-coupons-zopping-question4":
    "How can I generate multiple unique coupons?",
  "create-discount-coupons-zopping-question4-answer":
    "To generate multiple unique coupons:",
  "create-discount-coupons-zopping-question4-step-heading1": "Step 1",
  "create-discount-coupons-zopping-question4-step1":
    "Install the ‘ <span style='color: #000'>Unique Coupon Generator</span>’ extension <br>under  <span style='color: #000'>‘Marketing’</span> extensions",
  "create-discount-coupons-zopping-question4-step-heading2": "Step 2",
  "create-discount-coupons-zopping-question4-step2":
    "Once the extension is installed, go to the<br>  <span style='color: #000'>‘Coupons’</span> menu.",
  "create-discount-coupons-zopping-question4-step3":
    "You will notice the  <span style='color: #000'>‘+Add Unique Coupon’</span> button<br> on the top right. Click that button to open a form.",
  "create-discount-coupons-zopping-question4-step-heading3": "Step 3",
  "create-discount-coupons-zopping-question4-step4":
    "Give a  <span style='color: #000'>prefix and suffix</span> and the number of unique <br>coupons that you would like to create. This will<br> generate the desired number of coupons that<br> follow the same terms and conditions as<br> configured on the<span style='color: #000'>create unique coupon page</span>.",

  "google.api.key.title": "How to Create a Google Maps API Key",
  "google.api.key.description":
    "To unlock some advanced features and extensions on Zopping, you would need to configure a Google Maps API key on the Zopping Dashboard or the app.",
  "google.api.key.metadata":
    "Learn how you can configure Google Maps API Key on Zopping",
  "google-api-key-question1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>What is a Google Maps API Key and why is it necessary?</span>",
  "google-api-key-answer1-para":
    "Google Maps API Key is a private alphanumeric text provided by Google. You can use your private Google Maps API Key to unlock some of the Google Maps location functionalities on your online store and provide better experience to your customers.",
  "google-api-key-answer1-para2":
    "Some of the applications of using Google Maps API Key in your online store include, collecting your customers’ delivery locations, charging different delivery fees based on the delivery location of your customer, displaying your store location to your customers, etc.",
  "google-api-key-question2-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How can I get a Google Maps API Key?</span>",
  "google-api-key-answer2-para":
    "Google Maps API Key is available on <b><a href='https://mapsplatform.google.com/'target='_blank' rel='noopener noreferrer' style='color:#00BE00';>Google Maps Platform</a></b>, which is a product of Google Cloud. To get started, you need to create an account on Google Cloud. A valid Google Cloud account provides you with access to the Google Maps Platform along with several other Google Cloud products.",
  "google-api-key-question3-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Once you have a valid Google Cloud account, follow the below steps to create a Google Maps Platform account.</span>",
  "google-api-key-answer3-step1":
    "Once you are logged into your Google Cloud console, navigate to the Google Maps Platform from the side menu bar.",
  "google-api-key-answer3-step2": "Create a new Google Maps project.",
  "google-api-key-answer3-step3": "Set up a billing account for this project.",
  "google-api-key-answer3-step4":
    "Link your Google Maps project to the newly created billing account.",
  "google-api-key-question4-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Things that needs to be done get a valid and functioning Google Maps API Key:</span>",
  "google-api-key-answer4-para1":
    "You should have a valid billing account setup for Google Maps Platform. Having a Google Cloud Console billing account won’t be sufficient.",
  "google-api-key-answer4-para2":
    "Your billing account needs to have a credit card linked. Google verifies your credit card by deducting a nominal amount (it will be refunded typically in a day or so).",
  "google-api-key-question5-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Once you have a valid Google Maps Platform project and a billing account associated with it, follow the below steps to find your Google Maps API Key.</span>",
  "google-api-key-answer5-step1": "Login to your Google Maps Platform account.",
  "google-api-key-answer5-step2": "Click on Credentials from the menu bar.",
  "google-api-key-answer5-step3":
    "Under the API Keys section, click on Show Key.",
  "google-api-key-answer5-step4":
    "This shows a pop-up with the value of your Google Maps API Key.",
  "google-api-key-question6-heading":
    "Where to configure Google Maps API Key in Zopping?",
  "google-api-key-answer6-para":
    "Before you configure your Google Maps API key, ensure that the following APIs are enabled. These are the APIs that are used in Zopping. Not having any of these APIs enabled can hamper the functionality of your online store.",
  "google-api-key-answer6-step1":
    "Geocoding API - helps you find the latitude and longitude of your customer's delivery location.",
  "google-api-key-answer6-step2":
    "Maps JavaScript API - Helps you render a Google Map on your online store or the dashboard.",
  "google-api-key-answer6-step3":
    "Maps SDK for Android - Helps you render a Google Map on your Android mobile app.",
  "google-api-key-answer6-step4":
    "Places API - Auto-suggests landmarks on your online store.",
  "google.api.key.login.dashboard.heading":
    "Once you have the above APIs enabled, login to your Zopping Dashboard or Admin App",
  "google.api.key.question.cost.heading":
    "How much does a Google Maps API Key cost?",
  "google.api.key.answer.cost.para1.description":
    "Google Maps API Key is priced based on the number of times the APIs are utilized. Each API has a different rate. Refer to the <b><a href='https://mapsplatform.google.com/pricing/'target='_blank' rel='noopener noreferrer' style='color:#00BE00';> Google Maps pricing page</a></b> to understand how different APIs are priced. ",
  "google.api.key.answer.cost.para2.description":
    "However, Google gives $200 free credit every month, which is sufficient for a small and medium business that receives anything around 1000 orders a month. Any billing above $200 is deducted from your credit card. You also have an option to set budgets and alerts so that you can always stay within the free credit limit given by Google and stay alerted when you get close to it.",
  "google.api.key.answer.cost.para3.description":
    "For any further support related to configuring Google Maps API Key on Zopping, please write to us on <b><a href='mailto:support@zopping.com'target='_blank' rel='noopener noreferrer' style='color:#00BE00';> support@zopping.com</a></b>   ",
  "google-api-key-setup":
    "You will find a name of Google API key.Paste <br/> the Google map API Key here and hit save.",
  "click-on-setting": "Click On Settings",
  "click-on-api-inegration": "Click On API Integration",
  "add.product.title": "How to Add Products to Your Online Store",
  "add.product.description":
    "After setting up your eCommerce website or app, it is time to start adding products to your website. With the Zopping app, it is super easy.",
  "add.product.more.descriptions.1":
    "To make your store look pretty, we have added a few dummy products related to the industry that you have chosen at the time of signup. You can find these dummy products under the <b style='color:black'>Products</b> menu. You are free to edit these products by clicking on the product or delete them by clicking on the menu icon and choosing the <b style='color:black'>Delete</b> option.",
  "add.product.more.descriptions.2":
    "In addition to the above-mentioned dummy products, you can add an <b style='color:black'>unlimited number of products for free.</b>",
  "add.product.heading.title":
    "Steps to add a new product to your eCommerce store:",
  "add.product.imgcontent.one":
    "1. Click on the <b style='color:black'>+ Add Product</b> button in the <b style='color:black'>Products</b> menu.<br></br>",
  "add.product.imgcontent.two":
    "2. Fill up the details that are related to the product like product name, image, category, price and stock, etc<br></br>",
  "add.product.imgcontent.three":
    "3. Click on the <b style='color:black'>Save</b> button to save your product<br></br>",
  "add.product.para.1.description":
    "To preview your product, click on the menu icon next to the product and choose the <b style='color:black'>View on Website</b> option. This will open the product details page of your online store on the browser.",
  "add.product.more.heading.title":
    "Explanation of the fields on the Add Product page:",
  "add.product.productname.para":
    "<b style='color:Black;font-size:22px'>Name</b> <br></br><b style='color:#181912;font-weight:400'>Enter your product name.This will be displayed on your online store.</b>",
  "add.product.productname.para.example": "Example: Men’s Running Shoes",
  "add.product.category.para":
    "<b style='color:Black;font-size:22px'>Categories</b> <br></br><b style='color:#181912;font-weight:400'>You can group your products into one or more categories.These categories will appear on the menu bar of your online store. If you have already created a category, you can search and assign your product to this category or simply create a new category on the fly.</b>",
  "add.product.category.para.example":
    "Example: Men’s Shoes, Running Shoes<br></br>",
  "add.product.category.para.example.note":
    "<b style='color:black'>Note:</b> It is mandatory to assign a category to your product. Else, your product will not be visible on your website.",
  "add.product.description.para":
    "<b style='color:Black;font-size:22px'>Description</b><br></br> <b style='color:#181912;font-weight:400'>This is the place where you can mention additional information about your product. This can include technical specifications, features, benefits, dimensions, warranty, etc</b>",
  "add.product.description.para.example": "Example:",
  "add.product.description.para.example.one": "Shoe Width: Medium",
  "add.product.description.para.example.two": "Outer Material: Mesh",
  "add.product.description.para.example.three": "Toe Style: Round Toe",
  "add.product.description.para.example.four": "Warranty: 90 days",
  "add.product.upload.image.para":
    "<b style='color:Black;font-size:22px'>Product images </b><br></br><b style='color:#181912;font-weight:400'>Here, you can upload the images of your product either via Camera or from your Gallery. While you can add an unlimited number of images, we only support .jpeg, .png and .jpg formats and the images should be less than 20 MB in size.</b>",
  "add.product.barcode.image.para":
    "<b style='color:Black;font-size:22px'>Barcode</b><br></br><b style='color:#181912;font-weight:400'>If you are using our Smart POS app along with the Zopping app, then adding barcodes here will enable you to scan the barcodes of the products on the POS app. If you are not using the Smart POS app, then it is not necessary to add these details.</b><br></br>",
  "add.product.barcode.note.para":
    "<b style='color:Black'>Note:</b> You can add more than one barcode to your product.",
  "add.product.sellingprice.para":
    "<b style='color:Black;font-size:22px'>Selling Price </b><br></br><b b style='color:#181912;font-weight:400'>This is the MRP or the actual price or the price printed on your product inclusive of all taxes.</b><br></br>",
  "inclusive.all.taxes": "inclusive of all taxes",
  "add.product.sellingprice.note.para":
    "<b style='color:Black'>Note:</b> This may not be the price that you wish to sell. Products with no <b style='color:black'>Selling Price</b> will not appear on your online store.",
  "add.product.discount.para":
    "<b style='color:Black;font-size:22px'>Discount</b><br></br><b style='color:#181912;font-weight:400'>This is the discount that you would like to offer on the Selling Price of your product. The price that the customer will be paying is </b><b style='color:black'>Selling Price - Discount</b>.",
  "add.product.tax.para":
    "<b style='color:Black;font-size:22px'>Taxes </b><br></br><b style='color:#181912;font-weight:400'> Based on the country that you are located and the tax rate applicable for that particular product, you can enter the tax percentage.This is option</b><br></br>",
  "add.product.tax.note.para":
    "<b style='color:Black'>Note: Selling Price - Discount </b> is the price that your customer will pay. The tax percentage entered here is used only for generating the invoice for your customers.",
  "add.product.quantity.para":
    "<b style='color:Black;font-size:22px'>Quantity </b><br></br><b style='color:#181912;font-weight:400'> You can mark the quantity as</b> <b style='color:black'>Available</b><b style='color:#181912;font-weight:400'> if you have the necessary stock. In case the stock is over, you can mark it </b><b style='color:black'>Out of Stock</b><b style='color:#181912;font-weight:400'> and your customers won’t be able to order these products.</b><br></br>",
  "add.product.quantity.note.para":
    "<b style='color:Black'>Note: </b>Products that are out of stock, will appear on your website but will be marked as <b style='color:Black'>Out of Stock</b>.",
  "add.product.additional.para":
    "In addition to the above fields, there is more information that can be added to further manage and customise your catalogue. This is done via installing Extensions. These extensions are available under <b style='color:black'>Settings > Extensions > Catalogue</b>",
  "how.to.customize.footer.title":
    "How to customize the footer of your online store",
  "how.to.customize.footer.description":
    "The footer is the bottom-most section of your eCommerce website. It generally contains contact details of your company and links to secondary pages.",
  "how.to.customize.footer.more.descriptions.1":
    "With Zopping, you have the flexibility to customise the footer of your website by visiting the <b style='color:#181912'>Contact Details</b> page under the <b style='color:#181912'>Settings</b> menu. There are 4 major blocks that you can add. These include:",
  "how.to.customize.para.1.description":
    "<h style='color:#181912;font-size:1.571rem'>Contact Details: </h> You can use the footer of your website to display the email address, phone number and address of your store. Your customers can look up these details to get in touch with you.",
  "how.to.customize.para.2.description":
    "<h style='color:#181912;font-size:1.571rem'>Social Media Accounts: </h> If you have a presence on any of the social media platforms like YouTube, Facebook, Instagram, Twitter or WhatsApp, you can put in the necessary username/ handle/ phone number. When you add these details, they will appear on the footer with the corresponding social media icons.",
  "how.to.customize.para.3.description":
    "<h style='color:#181912;font-size:1.571rem'>Mobile Apps: </h> Along with the eCommerce website, we also provide free mobile apps for iOS and Android platforms. You can promote these apps on your website by adding the links to the App Store or Play Store.",
  "how.to.customize.para.4.description":
    "<h style='color:#181912;font-size:1.571rem'>Quick Links: </h> This is where you can add secondary pages like About Us, Shipping Policy, Cancellation or Refund Policy, Privacy Policy, and Terms and Conditions. In addition to this, you can design and add pages that convey additional information about you and your store to your customers.",
  "how.to.customize.para.5.description":
    "Having a well structured and content-rich footer elevates your brand image. So, why wait?",
  "set.up.your.footer.now": "Set up your footer now!",

  "add.products.faqs.title": "Add Products - FAQs",
  "add.products.faqs.question.one.para.description":
    "How can I add products that have multiple variants or options like size, colour, weight, etc?",
  "add.products.faqs.answer.one.para.description":
    "To add variants or options to the same product, you will have to install the <b style='color:#181912;'>Product Variant Support</b> extension. You will find this extension by navigating to <b style='color:#181912;'>More > Settings > Extensions > Catalogue > Product Variant Support.</b> Once the extension is installed, you will see the <b style='color:#181912;'>Add Variant</b> button on the <b style='color:#181912;'>Add Product</b> page using which you can add multiple variants, assign images, set different prices and stock",
  "add.products.faqs.question.two.para.description":
    "How many products can I add to my website?",
  "add.products.faqs.answer.two.para.description":
    "There is no limit to the number of products that you can add to your eCommerce store or the app.",
  "add.products.faqs.question.three.para.description":
    "I have a huge catalogue. Can I upload all those products at once?",
  "add.products.faqs.answer.three.para.description":
    "Yes, it is possible. Adding products in bulk is a feature that is currently available only on the web application. To access our web application, sign in to your Dashboard from <b><a href='https://zopping.com/'target='_blank' rel='noopener noreferrer' style='color:#00BE00';>www.zopping.com</a></b>.",
  "add.products.faqs.question.four.para.description":
    "Why is my product not appearing on the website even after adding it through the app or Zopping dashboard?",
  "add.products.faqs.answer.four.para.description":
    "There could be several reasons.",
  "add.products.faqs.answer.four.para.description.one":
    "It generally takes 5 - 10 minutes for the newly added products to appear on the website. Please wait for a few minutes and revisit your website",
  "add.products.faqs.answer.four.para.description.two":
    "Not all products will be visible on the Homepage of your website. To locate your product, click on the <b style='color:#181912';>Category</b> under which you have added that product",
  "add.products.faqs.answer.four.para.description.three":
    "Products with no selling price set, will not appear on the website. If you have not set the price, please set a price and check again.",
  "add.products.faqs.answer.four.para.description.four":
    "If the product has been disabled, then it will not appear on the website. Please enable it by pressing the toggle button.",
  "add.products.faqs.paragraph.description":
    "<p style='color:#181912';>If the problem persists, drop us an email at <b style='color:#00BE00;'>support@zopping.com</b> or call us on<b style='color:#00BE00;'> +91 9916814808.</b>",
  "explore.faqs": "Explore FAQs",
  "add.products.faqs.answer.short.para.description":
    "To add variants or options to the same product, you will have to install the Product Variant Support extension. You will find this extension by navigating to More > Settings > Extensions > Catalogue > Product Variant Support. Once the extension is installed, you will see the Add Variant button on the Add Product page using which you can add multiple variants, assign images, set different prices and stock",

  "how.to.add.logo.favicon.title":
    "How to Add Store Logo and Favicon to your Online Store",
  "how.to.add.logo.favicon.description":
    "To make your eCommerce website or app look genuine and reflect your brand, it is important to add your store logo and favicon.",
  "how.to.add.logo.favicon.heading.title": "Where does the store logo appear?",
  "how.to.add.logo.favicon.para.1.description":
    "The store logo appears on the header section of every page of your online store. It will also be visible in the emails (like Order placed, Reset password, Order delivered, Order cancelled, etc) that are automatically sent to your customers.",
  "how.to.add.logo.favicon.more.heading.title":
    "Where does the favicon appear?",
  "how.to.add.logo.favicon.additional.para.1.description":
    "Favicon is a tiny image that is visible on the tab of your browser.",
  "how.to.add.logo.favicon.more.heading.title.one":
    "<h style='color:#181912;font-size:1.571rem'>How to configure your store logo and favicon?</h>",
  "how.to.add.logo.favicon.imgcontent.one":
    "1. Click on the <b style='color:black'>More</b> menu and choose <b style='color:black'>Settings</b><br></br>",
  "how.to.add.logo.favicon.imgcontent.two":
    "2. Click on <b style='color:black'>Basic Information</b><br></br>",
  "how.to.add.logo.favicon.imgcontent.three":
    "3. Click on the <b style='color:black'>Upload your logo</b> section, and choose the logo from your Gallery<br></br>",
  "how.to.add.logo.favicon.imgcontent.four":
    "4. Click on the <b style='color:black'>Upload Favicon</b> section, and choose the favicon from your Gallery<br></br>",
  "how.to.add.logo.favicon.imgcontent.five":
    "5. Click on <b style='color:black'>Save</b><br></br>",
  "how.to.add.logo.favicon.para.2.description":
    "You can now click on <b style='color:black'>View Store</b> from the <b style='color:black'>Home</b> page to see your online store with your logo and favicon.",
  "how.to.add.logo.favicon.more.heading.title.two":
    "<h style='color:#181912;font-size:1.571rem'><ul><li>Note:</li></ul></h>",
  "how.to.add.logo.favicon.note.para.one.description":
    "<p style='color:#181912'>1. Your logo cannot be more than 2 MB in size</p>",
  "how.to.add.logo.favicon.note.para.two.description":
    "<p style='color:#181912'>2. Only .jpeg, .png and .jpg formats are allowed</p>",
  "how.to.add.logo.favicon.note.para.three.description":
    "<p style='color:#181912'>3. For a better look, upload a logo and favicon that have a transparent background</p>",
  "how.to.add.logo.favicon.note.para.four.description":
    "<p style='color:#181912'>4. For favicon, choose an image that has an aspect ratio of 1:1</p>",
  "make.technology.accessible":
    "We make technology accessible to every retailer around the world",
  "no.code.online-store":
    "Zopping is a no-code online store builder by Zopsmart (Raramuri Consultancy Services LLP). We help brands, retailers, resellers, SMEs, online-first entrepreneurs and hobbyists to set up and run robust and scalable online stores and mobile apps of their own in less than 10 minutes.",
  "customizable.platform.intuitive":
    "Our intuitive and customizable platform helps those with no web designing or development skills to easily list their products, manage orders, receive payments, handle deliveries, promote their brands and grow online. ",
  "happy.store.owners": "Happy Store Owners",
  "countries.text": "Countries",
  "online.sales.text": "Online Sales",
  "suite.mobile.apps":
    "Our suite of iOS and Android mobile apps enable store owners and their staff to handle their operations on the go.",
  "for.store.owners": "For Store Owners",
  "zopping.app.text": "Zopping App",
  "for.store.staff": "For Store Staff",
  "picker.app.text": "Picker App",
  "for.delivery.agents": "For Delivery Agents",
  "delivery.app.text": "Delivery App",
  "for.customers.text": "For Customers",
  "your.app.text": "Your App",
  "life.at.zopping": "Life at Zopping",
  "life.zopping.description":
    "Fun, innovation and collaboration packed in one place.",
  "addtional.charges":
    "*Additional charges apply if your app is hosted by Zopping or any other set up services.",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.title":
    "How to accept payments from customers for their orders on Zopping?",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description":
    "With Zopping, you can accept payments from your customers for their orders using two methods - Cash on Delivery (COD) and Online Payment.",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description1":
    "You can configure the payment methods by visiting Settings on your dashboard and clicking on Payments submenu.",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description2":
    "Note: You need to have at least one payment option configured at any point of time.",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description3":
    "In this post, we will explain how to configure an Online Payment method which will enable you to accept online payments from your customers.",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step1":
    "1. Click on Settings menu",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step2":
    "2. Click on Payments submenu",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step3":
    "3. This will load a dropdown through which you can select your desired payment gateway.",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step4":
    "4. From the list of payment gateways that appear, choose your preferred one",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step5":
    "5. Enter your Merchant or API keys, as required",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step6":
    "6. Choose the payment modes (Netbanking, UPI, Credit cards, Debit cards, Wallets, etc) that you wish to allow for that payment gateway",
  "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step7":
    "7. Click on Save",
  "online.payment.method.blog.title":
    "How to get the merchant and API keys from your payment gateway? ",
  "how.to.get.the.merchant.and.api.keys.from.your.payment.gateway":
    "<h style='color:#181912;font-size:1.571rem'>How to get the merchant and API keys from your payment gateway?</h>",
  "paytm.api.setup.title": "How to get API key for Paytm ?",
  "paytm.api.setup.description":
    "If you are already a registered merchant on Paytm, you will find the API Keys by following the below steps:",
  "paytm.api.setup.steps":
    "1. Login to <a href='https://business.paytm.com/' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> Paytm for Business account </a><br> 2. Click on API Keys under Developers menu<br> 3. Click on Product API Details tab",
  "paytm.api.setup.description.para1":
    "This will show the Merchant ID, Merchant Key, Industry Type and Website details which can be copied into the Zopping page.",
  "paytm.api.setup.description.para2":
    "If you do not have a valid business account with Paytm, you can follow <a href='https://business.paytm.com/blog/how-to-generate-api-key-for-payment-gateway-integration-new-merchants/' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> this guide </a> to get one.",
  "paytm.api.setup.description.para3":
    "Note: Paytm compliance team does a sanity check of your online store by looking for the below details on the home page of your online store. They can either disable your API keys or set a cap on the money you can collect in a month via these API keys, if they find the following information missing. So, please ensure that you have them configured.",
  "paytm.api.configure.point1":
    "Contact Us (This can be configured by visiting Settings > Contact Details)",
  "paytm.api.configure.point2": "Product details and pricing",
  "paytm.api.configure.point3":
    "About Us (Add content to this page by visiting Settings > Static Pages)",
  "paytm.api.configure.point4":
    "Privacy Policy (Add content to this page by visiting Settings > Static Pages)",
  "paytm.api.configure.point5":
    "Terms and Conditions (Add content to this page by visiting Settings > Static Pages)",
  "paytm.api.configure.point6":
    "Cancellation/ Refund Policy (Add content to this page by visiting Settings > Static Pages)",

  "payu.api.setup.title": "How to get API key for PayU ?",
  "payu.api.setup.description":
    "If you are already a registered merchant on PayU, you will find the API Keys by following the below steps:",
  "payu.api.setup.steps":
    "1. Login to <a href='https://onboarding.payu.in/app/account' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> PayU Dashboard </a><br> 2. Select Payment Gateway under Collect Payments from the menu on the left-pane<br> 3. Scroll down to the Key Salt Details section. The values for the following fields are generated automatically (for the first time) and displayed.",
  "payu.api.setup.description.para1":
    "Copy these details and paste them on your Zopping dashboard.",
  "payu.api.setup.description.para2":
    "If you do not have a valid business account with PayU, you can follow <a href='https://devguide.payu.in/merchant-integration/getting-started-with-web-checkout/register-for-a-merchant-account/' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> this guide </a> to get one.",
  "payu.api.setup.description.para3":
    "Note: PayU compliance team does a sanity check of your online store by looking for the below details on the home page of your online store. They can either disable your API keys or set a cap on the money you can collect in a month via these API keys, if they find the following information missing. So, please ensure that you have them configured.",
  "payu.api.configure.point1":
    "Contact Us (This can be configured by visiting Settings > Contact Details)",
  "payu.api.configure.point2": "Product details and pricing",
  "payu.api.configure.point3":
    "About Us (Add content to this page by visiting Settings > Static Pages)",
  "payu.api.configure.point4":
    "Privacy Policy (Add content to this page by visiting Settings > Static Pages)",
  "payu.api.configure.point5":
    "Terms and Conditions (Add content to this page by visiting Settings > Static Pages)",
  "payu.api.configure.point6":
    "Cancellation/ Refund Policy (Add content to this page by visiting Settings > Static Pages)",

  "razorpay.api.setup.title": "How to get API key for RazorPay ?",
  "razorpay.api.setup.description":
    "If you are already a registered merchant on Razorpay, you will find the API Keys by following the below steps:",
  "razorpay.api.setup.steps":
    "1. Login into your <a href='https://dashboard.razorpay.com/signin?screen=sign_in' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> RazorPay Dashboard </a> with appropriate credentials<br> 2. Select the Live mode for which you want to generate the API key<br> 3. Navigate to Settings → API Keys → Generate Key to generate key for the selected mode",
  "razorpay.api.setup.description.para1":
    "The Key Id and Key Secret appear on a pop-up page. Copy these and paste them on your Zopping dashboard.",
  "razorpay.api.setup.description.para2":
    "If you do not have a valid business account with Razorpay, you can follow <a href='https://razorpay.com/docs/payments/dashboard/settings/api-keys' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> this guide </a> to get one.",
  "razorpay.api.setup.description.para3":
    "Note: Razorpay compliance team does a sanity check of your online store by looking for the below details on the home page of your online store. They can either disable your API keys or set a cap on the money you can collect in a month via these API keys, if they find the following information missing. So, please ensure that you have them configured.",
  "razorpay.api.configure.point1":
    "Contact Us (This can be configured by visiting Settings > Contact Details)",
  "razorpay.api.configure.point2": "Product details and pricing",
  "razorpay.api.configure.point3":
    "About Us (Add content to this page by visiting Settings > Static Pages)",
  "razorpay.api.configure.point4":
    "Privacy Policy (Add content to this page by visiting Settings > Static Pages)",
  "razorpay.api.configure.point5":
    "Terms and Conditions (Add content to this page by visiting Settings > Static Pages)",
  "razorpay.api.configure.point6":
    "Cancellation/ Refund Policy (Add content to this page by visiting Settings > Static Pages)",

  "step.by.step.guide.description":
    "A detailed guide to configuring your online store and to start selling online.",
  "step.by.step.guide":
    "A Step-by-step guide to building your eCommerce store using Zopping",
  "step-by-step-para1":
    "Creating an online store is the first step in your journey to growing your business online. Since your customers will use the online store to make their purchases, you need to ensure that it is configured and made to function as per your business and customers’ needs. A badly and incorrectly configured online store can have a detrimental effect on your brand and might shun away your customers forever. Hence it is important to configure all the elements correctly to make your store fully functional.",
  "step-by-step-para2":
    "To ease up this process, we have written this detailed guide to help you set up your online store.",

  "step-by-step-guide-step1-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>1. Add products </span>",
  "step-by-step-guide-step1-para":
    "One of the first things that you should do while creating your online store is to create your catalogue and make it available to your users so that they can browse and buy them from you. Having an updated, detailed and accurate catalogue is very important to attract and retain your customers.",
  "step-by-step-guide-step1-link":
    "<a href='https://zopping.com/blog/how-to-add-products-to-your-online-store' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> You can read this step-by-step guide on how to add products to your online store. </a>",

  "step-by-step-guide-step2-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>2. Customize your store </span>",
  "step-by-step-guide-step2.1":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>a. Upload Logo and favicon : </b>To make your online store truly yours, you need to upload your logo and favicon. The logo that you upload will also be used in any communications that happen between you and your customers.",
  "step-by-step-guide-step2.1-link":
    "<a href='https://zopping.com/blog/how-to-add-logo-and-favicon-to-your-online-store' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> You can read this step-by-step guide on how to add a logo and favicon to your website. </a>",
  "step-by-step-guide-step2.2":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>b. Choose Themes : </b>Zopping offers a bunch of free themes to choose from for your online store. By changing the theme, the look and feel of your online store changes without breaking or affecting its functionality.<br><br> <b style='color: #000000; font-weight: normal;'>To change the theme:</b>",
  "step-by-step-guide-step2.3":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>c. Choose brand colours : </b>Once you have chosen your theme, you can customise the primary and secondary colours to match your brand identity.<br><br> <b style='color: #000000; font-weight: normal;'>To change the primary and secondary colours, follow these steps: </b><br><br>",
  "step-by-step-guide-step2.4":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>d. Customize page : </b>Any eCommerce website typically has the following pages: Home page, Category Page, Product Detail Page, Search Results page, Cart Page, Checkout page and Blog page.<br><br> <b style='color: #000000; font-weight: normal;'>With Zopping, you have the option to customise your pages. Follow the below steps to do the same: </b><br><br>",
  "step-by-step-guide-step2.5":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>e. Update footer : </b>The footer section is present at the bottom of any website. Users typically go there to find some store-specific information like links to your social media accounts, contact details, policies, etc.<br><br>",
  "step-by-step-guide-step2.5-link":
    "<a href='https://zopping.com/blog/how-to-customize-footer' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> You can read this step-by-step guide on how to customise the footer of your online store </a> <br><br>",

  "step-by-step-guide-step3-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>3. Add a domain </span> <br><br>",
  "step-by-step-guide-step3-para":
    "Domain/ URL is the gateway through which your users reach your website. By default, we assign a Zopping subdomain for your online store (example: https://yourstorename.zopping.com). However, you have the option to link your domain to the online store created via Zopping. <br><br>",
  "step-by-step-guide-step3-link":
    "<a href='https://zopping.com/blog/custom-domain-setup' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> You can read this step-by-step guide on how to link your custom domain with your online store. </a> <br><br>",

  "step-by-step-guide-step4-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>4. Create policy pages </span><br><br>",
  "step-by-step-guide-step4-para":
    "When you are operating an online store, you want to ensure that you have well-defined policies related to privacy, order returns, shipping and other terms and conditions. Having these policies helps you build trust in the minds of your customers. Most of the payment gateway providers also look for these policies on your website before they allocate API keys (you will read this in the next section). Hence it is all the more important for you to have these pages written down. <br><br> <b style='color: #000000; font-weight: normal;'>To create these policy pages, follow the below steps: </b><br><br>",

  "step-by-step-guide-step5-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>5. Setup payments  </span><br><br>",
  "step-by-step-guide-step5-para":
    "Once you have done all the above tasks, it is time for you to decide how you would like to accept payments from your customers for their orders. On Zopping, we broadly support 3 modes of payments. They are <br><br>",
  "step-by-step-guide-step5-link":
    "<a href='https://zopping.com/blog/how-to-accept-online-payments-from-customers-for-their-orders-on-zopping' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> You can read this step-by-step guide on how to configure payments on your online store </a><br><br> ",

  "step-by-step-guide-step6-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>6. Setup shipping  </span><br><br>",
  "step-by-step-guide-step6-para":
    "Zopping is integrated with several 3rd party shipping providers to enable you to fulfil your orders locally or nationally and internationally. By using these integrations your order and customer details are automatically sent to the logistics partners and your order can be dispatched from the Zopping dashboard itself thereby eliminating the need for you to log in to your logistics partner’s dashboard. <br><br> <b style='color: #000000; font-weight: normal;'>To create these policy pages, follow the below steps: </b><br><br>",
  "step-by-step-guide-step6-para1":
    "<b style='color: #000000; font-weight: normal;'>To integrate Zopping with your logistics partner account, follow the below steps: </b><br><br>",

  "step-by-step-guide-step7-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>7. Setup delivery charges </span><br><br>",
  "step-by-step-guide-step7-para":
    "You have the option to set delivery charges as per the need of your business and the kind of goods you deal in. Below are the 3 models through which you can charge delivery fees to your customers <br><br>",
  "step-by-step-guide-step7.1":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>a. A flat delivery fee: </b>Visit the ‘Order Configuration' submenu under ‘Settings’ to configure the minimum order value, delivery fee and minimum order value for free delivery. <br><br>",
  "step-by-step-guide-step7.2":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>b. Distance-based delivery fee: </b>Install and configure the ‘Delivery Area Setup’ extension. Using this feature, you can create zones on a map and set a different delivery fee based on the delivery location. <br><br>",
  "step-by-step-guide-step7.3":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>c. Time-based delivery fee: </b>Install and configure ‘Delivery/ Pickup Slot Setup’ and ‘Delivery/ Pickup Slot Charges’ extensions to charge different delivery fees based on the slot in which the user wants their order to be delivered. Through this, you can configure surcharges or offer discounts on the delivery fees for lean slots, etc. <br><br>",
  "step-by-step-guide-step7-note":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>Note: </b>To use this feature, you need to configure the Google Maps API key. <br><br>",

  "step-by-step-guide-step8-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>8. Add tracking </span><br><br>",
  "step-by-step-guide-step8.1":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>a. Google Analytics: </b>Google Analytics offers you a window into understanding how you are acquiring customers, their behaviour on your online store, their purchase patterns and demographics, etc. You can also use it to create custom reports to gain useful insights into the performance of your online store <br><br>",
  "step-by-step-guide-step8-para1":
    "<b style='color: #000000; font-weight: normal;'>To configure Google Analytics for your store, follow the below steps: </b><br><br>",
  "step-by-step-guide-step8.2.1":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>b. Facebook Pixel: </b>Having a Facebook Pixel on your website is mandatory for you to run Facebook/ Instagram Ads. It also allows you to track key events on your online store like your customers visiting a page or adding items to their cart or making a purchase. To configure Facebook Pixel on your website, follow the below steps: <br><br>",
  "step-by-step-guide-step8.2.2":
    "This will insert Facebook Pixel on all the pages of your website and you will see all the eCommerce-related events being tracked. Install the ‘Facebook Pixel Helper’ Chrome extension to examine if the tag is firing and to see the data being passed<br><br>",
  "step-by-step-guide-step8.3":
    "<b style='color: #000000; font-size: 16px; font-weight: normal;'>c. Google Tag Manager: </b>Google Tag Manager is an excellent tool to manage all your tags in one place. This enables you to integrate a lot of analytics tools, chatbots, pop-ups, etc. With Zopping, you have the option to integrate with Google Tag Manager. To configure, follow these steps: <br><br>",

  "google-analytics-step1": "Click on the <b>Settings</b> menu",
  "google-analytics-step2": "Click on the <b>Extensions</b> submenu",
  "google-analytics-step3": "Go to the <b>Analytics</b> tab",
  "google-analytics-step4":
    "Install the <b>Google Analytics</b> extension and click on <b>Configure</b>",
  "google-analytics-step5":
    "Enter your <b>Universal Analytics ID</b> and the <b>Conversion Tag</b>",
  "google-analytics-step6": "Click <b>Save</b>",

  "facebook-analytics-step1": "Click on the <b>Settings</b> menu",
  "facebook-analytics-step2": "Click on the <b>Extensions</b> submenu",
  "facebook-analytics-step3": "Go to the <b>Analytics</b> tab",
  "facebook-analytics-step4": "Install the <b>Facebook Analytics</b> extension",
  "facebook-analytics-step5":
    "Once the extension is installed, click the <b>Configure</b> button on the <b>Facebook Analytics</b> extension card",
  "facebook-analytics-step6":
    "Enter the Facebook Pixel ID in the corresponding field and click <b>Save</b>",

  "google-tag-manager-step1": "Click on the <b>Settings</b> menu",
  "google-tag-manager-step2": "Click on the <b>API Integration</b> submenu",
  "google-tag-manager-step3":
    "Under the Google Tag Manager ID field, enter your Container ID. It typically looks something like this - <b>GTM-XXXXXXX</b>",
  "google-tag-manager-step4":
    "Click <b>Save</b>. This will put the corresponding GTM JavaScript code in the 'head' and 'body' tags of all the pages of your website",

  "change-theme-step1": "Click on the <b>Settings</b> menu",
  "change-theme-step2": "Click on the <b>Appearance</b> submenu",
  "change-theme-step3": "Click on the <b>Themes</b> tab",
  "change-theme-step4":
    "From the list of themes, choose the one that you prefer and click save",

  "payment-setup-step1": "Cash on Delivery (COD)",
  "payment-setup-step2": "Online Payment",
  "payment-setup-step3":
    "Customer Wallet: You need to configure at least one Online Payment method to be able to use this.",

  "shipping-support1": "Dunzo - for hyperlocal delivery",
  "shipping-support2": "Shiprocket - for hyperlocal and inter-city delivery",
  "shipping-support3": "FedEx - for international delivery",

  "integrating-logistics-step1": "Click on the <b>Settings</b> menu",
  "integrating-logistics-step2": "Click on the <b>Shipping</b> submenu",
  "integrating-logistics-step3":
    "Enable <b>Use 3rd party courier services</b>. Note: You need to configure the Google Maps API key to be able to use this feature.",
  "integrating-logistics-step4":
    "From the list of courier partners, choose the courier partner that you would like to integrate with and enter the necessary API detail and click <b>Save</b>. Learn where to get the API keys for the courier partners.",
  "integrating-logistics-step5":
    "Now, click on the <b>Stores</b> submenu under <b>Settings</b> menu",
  "integrating-logistics-step6": "Click on the store name",
  "integrating-logistics-step7":
    "Enter the contact email, phone number and the location of your store. This is necessary to share the pickup location and contact details with your courier partner. ",
  "integrating-logistics-step8": "Click <b>Save</b>",
  "integrating-logistics-step9":
    "When you visit your Orders menu, you will notice a <b>Dispatch</b> option for all orders that are under the <b>Packed</b> state.",

  "policy-page-step1": "Click on the <b>Settings</b> menu",
  "policy-page-step2": "Click on the <b>Appearance</b> submenu",
  "policy-page-step3": "Click on the <b>Pages</b> tab",
  "policy-page-step4": "Choose <b>Custom Pages</b>",
  "policy-page-step5":
    "You will see a list of pre-existing pages like <b>About Us</b>, <b>Privacy Policy<b>, etc. If you do not find the policy page that you are looking for, click <b>Add page</b> to create a new one",
  "policy-page-step6":
    "Give the page a title, URL and start to add content by choosing the layouts",
  "policy-page-step7": "Click <b>Save</b> to publish this page",
  "policy-page-step8":
    "You will see the published custom page in the footer of your website in a few minutes",

  "ps-colour-step1": "Click on the <b>Settings</b> menu",
  "ps-colour-step2": "Click on the <b>Appearance</b> submenu",
  "ps-colour-step3": "Click on the <b>Colours</b> tab",
  "ps-colour-step4":
    "Select the Primary and Secondary colours and click <b>Save</b>",

  "customize-page-step1": "Click on the <b>Settings</b> menu",
  "customize-page-step2": "Click on the <b>Appearance<b> submenu",
  "customize-page-step3": "Click on the <b>Pages</b> tab",
  "customize-page-step4": "Choose the page that you would like to customize",
  "customize-page-step5":
    "You will notice a bunch of default layouts added to your page. You can edit, remove or reorder these layouts.",
  "customize-page-step6":
    "You can also add new layouts by clicking on the <b>Add New Layout</b> button",
  "customize-page-step7":
    "From the options shown, choose the layout that best fits your needs",
  "customize-page-step8":
    "Add the necessary details like titles, images, videos, etc and click <b>Save</b>",
  "signup-sent-message": "We will send a password to this phone number",
  "signup-sent-email-message": "We will send a password to this email",
  Hyperlocal: "Hyperlocal",
  "Hyperlocal.descroption":
    " Zopping provides the perfect eCommerce platform to sell in your neighbourhood or locality and succeed in the digital marketplace. Here's why Zopping stands out from the rest.",
  "Create.Your.Online.Store": "Create Your Online Store",
  "Best.suited.for.selling": "Best suited for selling",
  Grocery: "Grocery",
  "Food.and.Beverage": "Food and Beverage",
  Medicine: "Medicine",
  Bakery: "Bakery",
  Diary: "Diary",
  Fish: "Fish and Meat",
  "Embrace.Convenience": "Embrace Convenience",
  "Embrace.Convenience.discription1":
    "Zopping offers store pickup and delivery options, catering to your customers' preferences.",
  "Embrace.Convenience.discription2":
    " Sync your catalog, inventory, orders, and customer data with Zopping's omnichannel Point of Sale (POS) system.",
  "Flexible.Order.Management": "Flexible Order Management",
  "Flexible.Order.Management.description1":
    "Define your service areas by pincodes/ area/ apartments and efficiently serve your local customer base. Zopping offers store pickup and delivery options, catering to your customer's preferences. ",
  "Flexible.Order.Management.description2":
    "Zopping supports businesses with multiple branches or stores, streamlining operations and reaching a broader customer base.",
  "Flexible.Order.Management.description3":
    " Incentivize specific pickup or delivery slots with surcharges or discounts.",
  "Flexible.Order.Management.description4":
    " Set limits to efficiently manage orders and prevent overwhelming demands during peak periods.",
  "Simplified.Delivery.Management": "Simplified Delivery Management",
  "Simplified.Delivery.Management.description1":
    " The Zopping Delivery app revolutionizes order handling, ensuring prompt and reliable service.",
  "Simplified.Delivery.Management.description2":
    " Varying delivery fees based on distance traveled ensure fair pricing while covering costs.",
  "Delight.your.customers": "Delight your customers",
  "Delight.your.customers.description1":
    " Provide a seamless and consistent shopping experience across your website and mobile app.",
  "Delight.your.customers.description2":
    " Offer the convenience of placing orders from anywhere, anytime.",
  "Zopping.Advantage": "Zopping Advantage",
  "Zopping.Advantage.description1":
    " Benefit from regular updates and ongoing improvements to stay ahead of the competition.",
  "Zopping.Advantage.description2":
    " Get your custom-branded native iOS and Android app up and running in less than a day effortlessly manage content from one single .",
  "Zopping.Advantage.description3":
    " place and it automatically updates on your website and mobile app .",
  "Engage.your.customer.with.Push":
    "Engage your customer with Push notifications",
  "Engage.your.customer.with.Push.description1":
    "Increase sales and engage customers in real-time with personalized messages, special offers, and new arrival notifications.",
  ONDC: "ONDC",
  "ONDC.full": "Open Network for Digital Commerce (ONDC)",
  "Join.ondc": "Join ONDC",
  "What.is.ondc": "What is ONDC?",
  "ONDC.description":
    "Join ONDC network with Zopping and sell your products anywhere in India!",
  "Why.Sell.on.ONDC": "Why should I sell on ONDC? ",
  "Reach.a.bigger.audience": "Reach a bigger audience",
  "Reach.a.bigger.audience.description":
    "Forget the hassles of reaching your target audience via multiple marketplaces. Upload your digital catalog using Zopping only ONCE and make it available for purchase on 8+ ONDC buyer apps (PayTM, Pincode, Magicpin, etc).",
  "Access.Real.time.Customer.Data": "Access Real time Customer Data",
  "Access.Real.time.Customer.Data.description":
    "No more remaining in the shadows of your own business. Gain complete access to customer data (including phone number, email, address), which will help you with reselling or upselling your catalog and growing your business.",
  "Pay.less.commision": "Pay less than 3% commissions",
  "Pay.less.commision.description":
    "Commissions often get eaten away with a lot of middlemen dealings thus hindering profits from reaching you. Marketplaces eat away major commission shares, which can go up to 30%! Keep your profits intact with ONDC.",
  "You.set.the.terms.for.your.business": "You set the terms for your business",
  "You.set.the.terms.for.your.business.description":
    "Your business should be run by your rules and principles ideal for your growth. Decide your own cancellation, shipping and return policies which will help you maintain a valuable customer service and ensure quality control.",
  "Receive.Timely.Payments": "Receive Timely Payments",
  "Receive.Timely.Payments.description":
    "You sell, you get the money. No more waiting for marketplaces to send your dues as you will be able to directly collect payments for purchases made by the customer.",
  "Why.trust.ONDC": "Why Should I Trust ONDC?",
  "Backed.by.India":
    "Backed by <span style='color:#4ab819'><br>Govt. of India</span>",
  "Sellers.registered":
    "Over <span style='color:#4ab819'>35,000 sellers</span><br> registered",
  "skus.sell":
    "More than <span style='color:#4ab819'><br>38 Lakhs SKUs</span><br>being sold",
  "live.in.citys.expanding":
    "Live in <span style='color:#4ab819'><br>236 cities</span> and<br> expanding",
  "Why.Trust.ONDC.FootNote":
    "And with over 15+ product categories supported, your business will now reach a wider group of buyers with the vastly growing ONDC network and its popularity.",
  "Why.use.Zopping": "Why Use Zopping?",
  "Zopping.ondc":
    "You get to built your own store’s website, in addition to broadcasting your products via ONDC",
  "Zopping.mobile.app":
    "You also get to host your dedicated Mobile App for your store",
  "Zopping.price":
    "Zopping offers accommodating price slabs for using the platform.",
  "Zopping.Support":
    "Zopping provides you with a personal account manager to help with training and support",
  "How.join.ondc": "How do I Join ONDC Network?",
  "ondc.faq": "Frequently Asked Questions",
  "ondc.faq.1": "What is the difference between Online Marketplaces vs ONDC?",
  "ondc.faq.1.ans":
    "<ul><li>In an online marketplace, both the buyers and sellers use one application/ platform while in the case of ONDC, the seller can onboarded on any platform/ application that is connected to the ONDC network and they will be able to transact.</li ><li>Unlike Online Marketplaces, ONDC is not a web or mobile application. It is only a network that facilitates multiple applications to interact with each other and enable eCommerce between sellers and buyers</li><li>Online marketplaces charge sellers hefty commissions for onboarding or on their sales. However, sellers pay a miniscule or fraction of an amount as commissions on ONDC</li><li>In the online marketplaces, the platform determines the terms and conditions, cancellation, shipment and refund policies. However, on ONDC, the sellers have an option to set the policies that favour their businesses and be able to negotiate with the buyers.</li></ul > ",
  "ondc.faq.2": "Where will I be able to see the orders that come from ONDC?",
  "ondc.faq.2.ans":
    "The orders from ONDC will be visible under the Orders menu on the Zopping Admin Dashboard.",
  "ondc.faq.3":
    "Can I still join ONDC if I sell on Amazon or similar online marketplaces?",
  "ondc.faq.3.ans":
    "Yes, absolutely! You will still be able to sell your inventory via ONDC network despite your presence on any other marketplaces.",

  "email.and.SMTP.setup":
    "How to set up Email and SMTP configurations for your online store on Zopping?",
  "email.and.SMTP.setup.meta.description":
    "Learn how you can do SMTP configurations for your online store on Zopping",
  "email.and.SMTP.setup.description":
    "In this post we are going to look at how you can configure Email and SMTP for your online store on Zopping so that you can send important email communications like order updates or campaigns from your email address to your customers.",
  "email.and.SMTP.setup.description1":
    "By default, when you create your online eCommerce website using Zopping, your customers receive order and account related emails from Zopping email address <a href='mailto:noreply@zopping.com' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> (noreply@zopping.com)</a> . However, you now have the option to configure your own email and SMTP details so that your customers will see mails reaching them from your email address.",
  "email.and.SMTP.setup.heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>Some of the benefits of configuring your own email address and SMTP:</span>",
  "email-and-SMT-setup.configration1":
    "Improves the trust in the eyes of your customers",
  "email-and-SMT-setup.configration2":
    "When your customers respond to your emails, you will be able to see their replies",
  "email-and-SMT-setup.configration3":
    "With Zopping name removed, you have full control over your brand communications",
  "email-comunications-heading":
    "<span style='color: #0A0A0A; font-size: 20px;'>With that sorted, let us broadly look at the types of email communications that happen between you and your customers.</span>",
  "email-comunications-para1":
    "<span style=' margin-left: 1.125rem;'>Account-related: When your customers signup, request for forget password, verification OTPs, etc.</span>",
  "email-comunications-para2":
    "<span style=' margin-left: 1.125rem;'>Order updates: When an order is placed or packed or dispatched or completed or cancelled or returned, etc.</span>",
  "email-comunications-para3":
    "<span style=' margin-left: 1.125rem;'>Marketing campaigns: When you run email campaign.</span>",
  "configure-Email-and-SMTP":
    "<span style='color: #0A0A0A; font-size: 20px;'>How to configure Email and SMTP:</span>",
  "configure-Email-and-SMTP-Hedaing1":
    "<span style='color: #0A0A0A; font-size: 22px;'>Step 1</span>",
  "configure-Email-and-SMTP-Hedaing1-para1":
    "Login to your Zopping Admin Dashboard.",
  "configure-Email-and-SMTP-Hedaing2":
    "<span style='color: #0A0A0A; font-size: 22px;'>Step 2</span>",
  "configure-Email-and-SMTP-Hedaing2-para1":
    "From the Settings menu, navigate to Communications submenu",
  "configure-Email-and-SMTP-Hedaing3":
    "<span style='color: #0A0A0A; font-size: 22px;'>Step 3</span>",
  "configure-Email-and-SMTP-Hedaing3-para1": "Click on ‘Email’ tab",
  "configure-Email-and-SMTP-Hedaing3-para2":
    "Configure the SMTP details and click Save",
  "email-service-provider":
    "<span style='color: #0A0A0A; font-size: 22px;'>Where to find the SMTP details of my email service provider::</span>",
  "email-service-provider-heading":
    "<span style='color: #0A0A0A; font-size: 22px;'>Gmail</span>",
  "email-service-provider-para1": "SMTP Host: smtp.gmail.com",
  "email-service-provider-para2": "SMTP Username: your Gmail email address",
  "email-service-provider-para3":
    "SMTP Password: follow this <a href='https://support.google.com/mail/answer/185833?hl=en' style='color:#4ab819'; >link</a> to get your SMTP password </br> (Note: This password will be visible only once)",
  "email-service-provider-para4": "SMTP Port: 587",
  "email-service-provider-para5": "Default Email: your Gmail email address",
  "email.support":
    "For any further support related to Email SMTP configurations on Zopping, please write to us on <a href='mailto:support@zopping.com' style='color: #4AB819' target='_blank' rel='noopener noreferrer'> support@zopping.com</a>",
  "enter.phone.number.placeholder": "Enter your phone number",
  "introduction.to.ondc": "Introduction to ONDC",
  "introduction.to.ondc.description":
    "A detailed guide on what ONDC is and how it helps you grow your business online",
  "what-ondc-is-ques1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>What is ONDC?</span>",
  "ondc-ans1-heading-para1":
    "ONDC (Open Network for Digital Commerce) is a Government of India initiative to create an inclusive and level playing ecosystem for eCommerce in India. ONDC aims to democratize the concept of online marketplace by providing an unbiased platform for all sellers, buyers and logistics providers. It also removes the possibility of any one business entity asserting monopoly in the market.",
  "ondc-ans1-heading-para2":
    "Currently, there are broadly 2 ways in which sellers/ merchants go about selling their products online: <br> 1. Sell on their own website <br> 2. Sell on online marketplaces like Amazon/ Flipkart/ Swiggy/ Myntra, etc.",
  "ondc-challenges-on-your-own-web-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Challenges of selling on your own website</span>",
  "ondc-challenges-on-your-own-web-heading-para":
    "- For any seller, setting up an online store is a hassle in itself and needs technical know-how <br> - From curating a catalog to establishing a loyal customer base, from managing orders, delivery and payments, everything has to be established by the seller. <br> - From a perspective of starting a brand new business, this seems like a cumbersome process and could force seller parties to shell out unplanned funds which might hamper their business plans",
  "ondc-challenges-in-online-marketplace-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Challenges of selling in an online marketplace</span>",
  "ondc-challenges-in-online-marketplace-heading-para":
    "- Sellers don’t have access to customer data which prevents them from upselling or cross selling their products <br> - Marketplaces charge huge commissions (sometimes as high as 30%), thereby affecting sellers’ margins <br> - The sellers are often pushed to agree with heavily biased terms and conditions set by online marketplaces <br> - This gives way for marketplaces to gain an upper hand in the market and dictate flow of purchase.",
  "how-ondc-operate-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How does ONDC operate?</span>",
  "how-ondc-operate-heading-para":
    "ONDC attempts at breaking this pattern of seeking out a singular source of dependency by pooling in all the relevant parties (the buyer, seller and the logistics service provider) and creating a distributed network where each Network Participant (NP) fulfills their share of responsibility and earns commissions accordingly.",
  "who-is-buyer-app-seller-app-and-logistic-service-provider":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Who is a Buyer App, Seller App and Logistics Service Provider?</span>",
  "buyer-app":
    "<span style='color: #181912; font-size: 18px; line-height:1.4rem'>1. Buyer App</span>",
  "buyer-app-points":
    "a. They provide a shopping interface (website/app) where the buyer can discover products or stores <br> b. They also are responsible for bringing in buyers and engaging with them <br> c. Exposes product inventory from various sellers <br> d. Payments and installments for the purchases are also mostly processed here <br> e. Order related queries or discrepancies are registered here <br> f. Rating and reviews for the ordered products are done here",
  "seller-app":
    "<span style='margin-top: 16px; color: #181912; font-size: 18px; line-height:1.4rem'>2. Seller App</span>",
  "seller-app-points":
    "a. These are the parties who are responsible for onboarding sellers/ merchants <br> b. They provide the online platform for sellers where they will create a digital catalog for the products they wish to sell <br> c. They also provide with a dashboard to the sellers for managing orders <br> d. They can either avail Logistics Service Providers (on-network) or use their own means of delivery (off-network) to ship the orders to the buyers <br> e. They also help coordinate with logistics service provider and buyer network participants to resolve customer issues with ongoing orders",
  "logistic-service-provider":
    "<span style='color: #181912; font-size: 18px; line-height:1.4rem'>3. Logistics Service Provider Network Participant</span>",
  "logistic-service-provider-points":
    "a. These parties are involved at the shipping and delivery stage of the e-commerce shopping experience <br> b. From giving delivery estimates to the buyer to deciding delivery costs, every detail is established here <br> c. Their services can be procured by either the buyer or the seller network participant, whatever suits the business better",
  "logistic-service-provider-point-discription":
    "As you could have noticed while reading all these pointers, it becomes clearer that at every stage of the shopping experience, every single network participant is getting an opportunity to actively contribute to what they do best and earn their due commision margins accordingly.",
  "how-does-ecommerse-purchase-flow-happens-on-ondc":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:1.8rem'>How does eCommerce purchase flow happens on ONDC</span>",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-1":
    "1. Buyer opens any Buyer app connected to the ONDC network (ex: PayTM/ Pincode/ Magicpin)",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-2":
    "2. Buyer searches for the products they wish to buy or look up stores that deliver to his/ her location",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-3":
    "3. Buyer app broadcasts the search request to all the Seller apps connected on the ONDC network",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-4":
    "4. All the Seller apps respond to the search request from the Buyer app with the matching product details and Sellers details",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-5":
    "5. From the given list, Buyer chooses the product(s) they wish to buy and adds them to the cart",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-6":
    "6. Seller app either calls the on-network Logistics Service Providers to provide a delivery fee along with the estimated delivery time or uses its own delivery fleet to arrive at the delivery cost and the time.",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-7":
    "7. Seller app shares the delivery charges and estimated delivery time to the Buyer via the Buyer app",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-8":
    "8. Buyer chooses the delivery partner based on the options shared by the Seller app",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-9":
    "9. Buyer makes the purchase, which includes cost of products, taxes, packaging charges, delivery fee and any other convenience charges (Ex: Buyer app or Seller app commissions or ONDC commissions)",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-10":
    "10. Seller receives the order along with the Buyer details via the Seller app",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-11":
    "11. Seller packs the order and dispatches it via the delivery partner chosen by the Buyer at the time of placing the order",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-12":
    "12. Buyer tracks the order via the Buyer App",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-13":
    "13. Buyer receives the order",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-14":
    "14. Buyer rates or reviews the product, seller and the delivery partner",
  "how-does-ecommerse-purchase-flow-happens-on-ondc-description-15":
    "15. Buyer app settles the money to the Seller app by withholding its commissions, if any",
  "advantage-of-ondc-from-a-seller-perspective":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:1.8rem'>Advantages of ONDC from a seller’s perspective-</span>",
  "advantage-of-ondc-from-a-seller-perspective-description-1":
    "Distributed Responsibility- ONDC would help you prioritize quality of the products instead of worrying about the other aspects of product sale, everything else will be taken care of by other Network Participants contributing to ONDC",
  "advantage-of-ondc-from-a-seller-perspective-description-2":
    "Access to buyer Data- Seller parties will now have complete access to buyer data as ONDC ensures transparency between parties during purchase process",
  "advantage-of-ondc-from-a-seller-perspective-description-3":
    "Single Source of Control- The seller will be required to upload his product catalog to a digital inventory only once, all the buyer entities present in the network will be able to view the inventory broadcasted via ONDC",
  "advantage-of-ondc-from-a-seller-perspective-description-4":
    "Ensure Market Plurality-  provides an even playground for all sellers to sell their products",
  "advantage-of-ondc-from-a-seller-perspective-description-5":
    "Interoperability- Establish clear lines of communication via Custom APIs among all entities involved (Buyer, Seller, Logistics Service Provider), which ensures transparency in conducting order of business",
  "how-will-zopping-help-using-ondc":
    "<span style='color: #0A0A0A; font-size: 22px; line-height: 1.8rem'>How will Zopping help you expand your business using ONDC?</span> <br>",
  "how-will-zopping-help-using-ondc-description-one":
    "1. With ONDC in place, the seller doesn’t have to worry about anything else except for curating the catalog and uploading a digital inventory of the same in Zopping, and Zopping will make sure that through ONDC, all their products will reach their target audience across all the buyer apps.",
  "how-will-zopping-help-using-ondc-description-two":
    "2. Own eCommerce website and mobile applications alongside selling on ONDC",
  "how-will-zopping-help-using-ondc-description-three":
    "3. The seller can also keep a track of the orders and get access to real time buyer data with reports and analytics in Zopping and come up with business strategies accordingly.",
  "how-will-zopping-help-using-ondc-description-four":
    "4. Zopping also ensures hassle free onboarding as we will provide you with a dedicated account manager for the same and guide you through the setup process",
  "signup.for.ondc.via.zopping":
    "<a href='/solutions/ondc' style='color: #fff' target='_self'>Signup on ONDC</a>",
  "product.variant.support.extension":
    " How to use Product Variant Support extension?",
  "product.variant.support.extension.description":
    "This extension enables store owners to add variants (for example: size, colour, weight, etc) to a single product instead of creating multiple products thus helping their customers to choose the desired variant from the same product page instead of navigating between multiple pages.",
  "what.is.product.variant.support.extension.qn1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>What is Product Variant Support Extension?</span>",
  "product.variant.support.extension.ans1":
    "This extension enables store owners to add variants (for example: size, colour, weight, etc) to a single product instead of creating multiple products thus helping their customers to choose the desired variant from the same product page instead of navigating between multiple pages.",
  "product.variant.qn2.heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Why do I need this extension?</span>",
  "product.variant.ans2":
    "Let’s take a real-time example. When you have to upload a product to your online catalog, you have to enter specific details (for eg., brand name, category, description, product image, etc.). Once that is done, your product will be visible on your website for sale. But oftentimes, if you have different variants (weight, colour, or size) of the same product available for sale, by logic, you would have to enter its corresponding product details in the product catalog for individual products. Here is where the Product Variant Support Extension comes in handy. This extension will help you define these variants under the same product with their identifiers. You can  define unique prices and taxes, images, and stock for each variant. ",
  prerequisites:
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Prerequisites?</span>",
  "prerequisites-para1":
    "Before installing this extension, please make sure that the checklist shown below has been looked over.</br>1. An active Zopping account with access to the Dashboard. </br>2. A product catalog should be present with existing products.",
  "how.to.install.product.varian-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How to install Product Variant Support Extension on Zopping:</span>",
  "product-variant-extension-setup-step1":
    "Navigate to the <span style='color: #0A0A0A; line-height:1.8rem'>Settings > Extensions > Catalogue section.</span>",
  "product-variant-extension-setup-step2":
    "Here you will find the <span style='color: #0A0A0A;  line-height:1.8rem'>Product Variant Support</span> extension. Click on<span style='color: #0A0A0A;  line-height:1.8rem'> Install</span> and add the extension to your website",
  "define.the.product.variants.for.my.products.heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How to define the product variants for my products?</span>",
  "define.the.product.variants.for.my.products.step1":
    "Navigate back to your product catalog, click on any existing product, and select the<span style='color: #0A0A0A; line-height:1.8rem'> Edit </span>option or simply add a new product.",
  "define.the.product.variants.for.my.products.step2":
    "On the  <span style='color: #0A0A0A; line-height:1.8rem'>Add/ Edit Product</span> page, scroll down to the<span style='color: #0A0A0A; f line-height:1.8rem'> Variants</span> section and enable it using the toggle button on the right side of the screen.",
  "define.the.product.variants.for.my.products.step3":
    "Once you have added the variant, it will show up as shown below. Here for example purposes, We have added two variants (blue, and red). Add all relevant product details (images, price, product ID, etc) for each variant and save.",
  "how.will.this.change.reflect.on.my.website.heading1":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How will this change reflect on my website? </span>",
  "how.will.this.change.reflect.on.my.website.description1":
    "When your customer views the product on your website, they will see the product details like this. Please note that the product shown below now has two variants (Pale blue, and red).Similarly, you can add other relevant variants for every product in your catalog. Please note that configuring the variants for a product is completely optional, which means you can continue to add products without necessarily adding variants to them even though the extension is installed. ",
  "how.will.this.change.reflect.on.my.website.heading2":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How to add multiple variants?</span>",
  "how.will.this.change.reflect.on.my.website.description2":
    "In a few scenarios, you might need to add multiple variants to the same product. Example: A T-shirt might have variants by colour and size. In such scenarios, each variant that you add needs to have a unique colour value and size value.",
  "how.will.this.change.reflect.on.my.website.heading2.1":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>To implement the multi-variant feature, follow the below steps:</span>",
  "how.will.this.change.reflect.on.my.website.description2.1":
    "1.<span style='margin-left:.75rem;'>After installing the Product Variant Support extension, navigate to the <span style='color: #0A0A0A;  line-height:1.8rem'>Extensions > Installed </span>section. You will find the same extension in this window. Click on the <span style='color: #0A0A0A;  line-height:1.8rem'>Configure</span> option.</span>",
  "how.will.this.change.reflect.on.my.website.description2.2":
    "2.<span style='margin-left:.75rem;'>Once you are on the configuration page, click on the <span style='color: #0A0A0A; line-height:1.8rem'>Enable Product Properties</span> checkbox. Here you can add the different properties that you would like to define for any product in general. In the screengrab shown here, This is a sample store set up for a clothing line. Hence the variants added have been size, color, material, etc.</span>",
  "how.will.this.change.reflect.on.my.website.description3":
    "3.<span style='margin-left:.75rem;'>Now, go to the edit product page as instructed before. When you click on the toggle button, a small window will open. Here you will be asked to add the relevant properties associated with the product you are dealing with. For example, if you are adding a product description for a saree, you would want to mention the color, and material as a variant. This is completely optional.</span>",
  "how.will.this.change.reflect.on.my.website.description4":
    "4.<span style='margin-left:.75rem;'>Under the <span style='color: #0A0A0A;  line-height:1.8rem'>Variants</span> section, you will see boxes with the selected properties (in this case, color) mentioned. Fill in with the required information and click on <span style='color: #0A0A0A;  line-height:1.8rem'>Add Variant</span>. Continue adding relevant product information for each one as shown.</span> ",
  "how.will.this.change.reflect.on.my.website.heading4":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How will this change reflect on my website?</span>",
  "what.happens.when.i.uninstall.this.extension.heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>What happens when I uninstall this extension?</span>",
  "what.happens.when.i.uninstall.this.extension.step1":
    "1.<span style='margin-left:.75rem;'>You can no longer add variants to products.</span>",
  "what.happens.when.i.uninstall.this.extension.step2":
    "2.<span style='margin-left:.75rem;'>All existing products with variants will no longer be a part of your product catalog.</span>",
  "what.is.seo.optimization.extension.title":
    "What is SEO Optimization Extension?",
  "what.is.seo.optimization.extension.description":
    "This Zopping extension will help you optimize your online store and all its corresponding web pages according to search engine preferences.",
  "what.is.seo.optimization.extension.para.1.heading1":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2.25rem'>Why do I need this extension?</span>",
  "what.is.seo.optimization.extension.para.1.step1":
    "1.<span style='margin-left:.75rem;'>This extension will provide you with a sitemap, which, once plugged in via Google Search Console or any other search engine dashboard, will be used by the respective search engine to crawl and index your website.</span>",
  "what.is.seo.optimization.extension.para.1.step2":
    "2.<span style='margin-left:.75rem;'>This extension will also help you programmatically optimize your page title, meta description(s), and meta keywords of your home, category, brand, tag, and product pages. This will help you rank your website higher with increasing relevance with keywords present on the internet.</span>",
  "what.is.seo.optimization.extension.para.2.heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2.25rem'>Prerequisites?</span>",
  "what.is.seo.optimization.extension.para.2.step1":
    "There are no prerequisites required to run this extension for your website.",
  "what.is.seo.optimization.extension.para.3.heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2.25rem'> How to install SEO Optimization Extension on Zopping?</span>",
  "seo-extension-setup-step1":
    "Navigate to the <span style='color: #0A0A0A;'>Settings > Extensions > Marketing section.</span>",
  "seo-extension-setup-step2":
    "Here you will find the <span style='color: #0A0A0A;'> SEO Optimization</span> extension. Click on ‘Install’ and add the extension to your website",
  "what.is.seo.optimization.extension.para.4.heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2.25rem'>How can I Implement SEO for my online store’s web pages?</span>",
  "what.is.seo.optimizaiton.extension.step1":
    "1.<span style='margin-left:.75rem;'>After installing the SEO Optimization extension, navigate to the<span style='color: #0A0A0A;'>  Extensions > Installed</span> section. You will find the same extension in this window. Click on the ‘Configure’ option.</span>",
  "what.is.seo.optimizaiton.extension.step2":
    "2.<span style='margin-left:.75rem;'>Once you land on the Custom SEO Content page, you will be shown a few tabs like <span style='color: #0A0A0A;'> Home, Product, Category, Brand, Tag,</span> and <span style='color: #0A0A0A; line-height:1.8rem'> Sitemap.</span> We will walk you through every step of how to add SEO content for each tab.</span>",
  "what.is.seo.optimizaiton.extension.step3":
    "3.<span style='margin-left:.75rem;'>In the <span style='color: #0A0A0A;'> Home</span> tab, you will notice that a template has been provided as shown above. The variable names mentioned within the brackets will be automatically replaced by relevant information on your website. For eg, the variable<span style='color: #0A0A0A;'> {organization_name}</span> will get replaced by your online store’s name.</span>",
  "what.is.seo.optimizaiton.extension.step4":
    "4.<span style='margin-left:.75rem;'>You can also add static texts in addition to these variable placeholders as shown below.</span>",
  "what.is.seo.optimizaiton.extension.step5":
    "5.<span style='margin-left:.75rem;'>Similarly, you can do the same for Product, Category, Tag, and Brand pages. The variable placeholders for the same will vary accordingly. Refer to the list below to check relevance-.</span>",
  "what.is.seo.optimization.extension.step5.point1":
    "<span style='color: #0A0A0A;'>{{product_name}}</span> - will fetch info of the relevant product name.",
  "what.is.seo.optimization.extension.step5.point2":
    "<span style='color: #0A0A0A;'>{{brand_name}}</span> - will fetch info of the relevant brand name.",
  "what.is.seo.optimization.extension.step5.point3":
    "<span style='color: #0A0A0A;'>{{category_name}}</span> - will fetch info of the relevant category name.",
  "what.is.seo.optimization.extension.step5.point4":
    "<span style='color: #0A0A0A;'>{{tag_name}}</span> - will fetch info of relevant tags.",
  "what.is.seo.optimization.extension.step5.point5":
    "<span style='color: #0A0A0A;'>{{organization_name}}</span> - will fetch your online store’s name.",
  "what.is.seo.optimization.extension.step6":
    "6.<span style='margin-left:.75rem;'>Please note that the Tag and Brand pages will only show up if the following two extensions are installed respectively- </span>",
  "what.is.seo.optimization.extension.step6.point1":
    "<span style='color: #0A0A0A;'>Product Tag Support </span> Extension for Tags.",
  "what.is.seo.optimization.extension.step6.point2":
    "<span style='color: #0A0A0A;'>Brand Support </span> Extension for Brand.",
  "what.is.seo.optimization.extension.step7":
    "7.<span style='margin-left:.75rem;'>To know more about keyword specificity and how to write the meta descriptions for these pages, please visit the <span style=' line-height:1.8rem'> <a href='https://developers.google.com/search/docs/fundamentals/seo-starter-guide' target='_blank' style='color: #4AB819;'> SEO Starter Guide</a> </span> from Google.</span>",
  "what.is.seo.optimization.extension.para5.heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2.25rem'>Sitemap:</span>",
  "what.is.seo.optimization.extension.para5.step1":
    "1.<span style='margin-left:.75rem;'>A sitemap is a file or web page that provides a list of all the pages and content on a website. It serves as a structured map of the website, making it easier for search engines and users to navigate and understand the site's organization.</span>",
  "what.is.seo.optimization.extension.para5.step2":
    "2.<span style='margin-left:.75rem;'>It is a valuable tool for online stores as it aids in search engine optimization, and ensures all pages are crawled and indexed. It's an essential component of an e-commerce site's SEO strategy.</span>",
  "what.is.seo.optimization.extension.para5.step3":
    "3.<span style='margin-left:.75rem;'>In the <span style='color: #0A0A0A;'> Sitemap </span> section, copy the sitemap URL for your online store that is shown below and register the same with <span style='color: #4AB819;'> <a href='https://search.google.com/search-console/about' target='_blank' style='color: #4AB819;'> Google Search Console. </a></span> To know more, refer<span style='color: #4AB819;'><a href='https://developers.google.com/search/docs/fundamentals/seo-starter-guide#howdoigetongoogle' target='_blank' style='text-decoration:none; color: #4AB819;'> here</a></span></span>",
  "what.is.seo.optimization.extension.para6.heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2.25rem'>How will this change affect my website?</span>",
  "what.is.seo.optimization.extension.para6.step1":
    "1.<span style='margin-left:.75rem;'>When your customer searches your online store on any search engine, they will see the website links as shown.</span>",
  "what.is.seo.optimization.extension.para6.step1.point1":
    "1.<span style='margin-left:.75rem;'>Custom Page Title.</span>",
  "what.is.seo.optimization.extension.para6.step1.point2":
    "2.<span style='margin-left:.75rem;'>Custom Meta Description.</span>",
  "what.is.seo.optimization.extension.para6.step1.point3":
    "3.<span style='margin-left:.75rem;'>Custom Product/ Categories/ Brand/ Tag Title.</span>",
  "what.is.seo.optimization.extension.para6.step1.point4":
    "4.<span style='margin-left:.75rem;'>Revelant Product/ Categories/ Brand/ Tag meta description..</span>",
  "what.is.seo.optimization.extension.para6.step2.point1":
    "2.<span style='margin-left:.75rem;'>When the user adds a custom product/ category title, the title of the corresponding webpages of relevant products/ categories will be visible as shown below to the customer.</span>",
  "what.is.seo.optimization.extension.para7.heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2.25rem'>What happens when I uninstall this extension?</span>",
  "what.is.seo.optimization.extension.para7.step1":
    "1.<span style='margin-left:.75rem;'>All SEO tags and descriptions for your online store web pages will be removed.</span>",
  "delivery.area.support.extension":
    " What is Delivery Area Support extension?",
  "delivery.area.support.extension.description":
    "This zopping extension will help you define the locations or boundaries where your store(s) can deliver your products.",
  "introduction.to.delivery.area.support.description":
    "Delivery Area Support extension will help you define the locations or boundaries where your store(s) can deliver your products.",
  "why.do.i.need.this.extension.delivery.support.qn1-heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:1.8rem'>Why do I need this extension?</span>",
  "delivery.area.support.extension.ans1":
    "1. <span style='margin-left:.25rem;'>If you are an owner of a hyperlocal store, it is of utmost importance for you to define an area where you will be able to deliver your products.<span><br> 2. <span style='margin-left:.25rem;'> If you run an F&B outlet, choosing too large a delivery area won’t be an apt option for running delivery errands.<br>  This extension will help you set a boundary for the delivery area beyond which you can choose not to deliver your products.<span><br> 3. <span style='margin-left:.25rem;'> If you have multiple stores and multiple locations, this extension will help you set a delivery region for each store according to their location, service availability, etc.<span><br> 4. <span style='margin-left:.25rem;'> This extension will also help you set different delivery prices for different distances/areas covered for delivery.<span>",
  "prerequisites.step.1":
    "You need to have a Google API Key. To know more about the same and how to get one, please refer <a style='color:#00BE00' href='https://developers.google.com/maps/documentation/javascript/get-api-key' target='_blank'>here</a>.",
  "prerequisites.step.2":
    "Once you have procured one, go to your Dashboard. Navigate to <span style='color:black'> Settings > API Integration.</span> Fill in the Google Maps API Key row and click on <span style='color:black'> Save.</span>",
  "prerequisites.step.3":
    "You also need to configure your store’s location in the Dashboard. Navigate to <span style='color:black'> Settings > Store > Edit Store.</span> Add your store’s location and other details. Click on <span style='color:black'> Submit </span> to save the changes.",
  "prerequisites.step.4":
    "Navigate to <span style='color:black'> Settings > Order Configuration.</span> Set the Order Type as Delivery. This will allow you to accept orders for delivery via your online store.",
  "how.to.install.delivery.support.extension":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>How to Install Delivery Area Support Extension on Zopping?</span>",
  "setup-step1":
    "Navigate to the <span style='color:black'> Settings > Extensions > Delivery section.</span> ",
  "setup-step2":
    "Here you will find the <span style='color:black'> Delivery Area Support extension.</span> Click on ‘Install’ and add the extension to your website.",
  "how.do.i.define.delivery.areas":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem;margin-bottom: 30px'>How do I define delivery areas for my store(s)?</span>",
  "define.point1":
    "Now go to the <span style='color:black'> Extensions > Installed</span> section. You will find the same extension in this window. Click on the <span style='color:black'>Configure</span> option.",
  "define.point2":
    "Once you are on the Delivery Area page, you will see these <span style='color:black'>Delivering Zones</span> columns. Here, you can define your delivery zones by <span style='color:black'>Zipcode, Distance From Store, Draw On Map, and Delivery By Apartment.</span>",
  "define.points3":
    "Click on the <span style='color:black'>Zipcodes</span> option. Select the store for which you wish to define the delivery area, label it in the ‘Zone Name’ field, define the delivery fee, and add the zipcode where delivery services will be available. Click on the <span style='color:black'>Add Group</span> option to save the changes.",
  "define.point4":
    "You can also add multiple zip code locations using the<span style='color:black'> Add New </span>option as shown below.",
  "define.point5":
    "Click on the <span style='color:black'> Distance from Store option.</span> Here you can define your delivery area by radius. Select the store for which you wish to define a delivery zone, label it, search the landmark location for your store or your delivery center and select it, then select the distance radius to cover for delivery services. Click on the <span style='color:black'>Add Group </span> option to save the changes.",
  "define.point6":
    "You can also add multiple similar delivery regions using the <span style='color:black'> Add New </span>option as shown below.",
  "define.point7":
    "Click on the <span style='color:black'> Draw on Map</span> option and select <span style='color:black'>Add New Zone.</span> As mentioned above, add the store, label the delivery zone, and add a delivery cost. On the right side of the screen, a map will be visible. Click on the <span style='color:black'> Draw on Map </span> option at the top center of the map and start marking the delivery regions using the cursor. Once selected, click on Add Group and save the changes.",
  "define.point8":
    "You can also add multiple similar delivery regions for the same store or different stores using the <span style='color:black'>Add New </span>option as shown below.",
  "define.point9":
    "Click on the <span style='color:black'> Delivery by Apartment</span> option and select <span style='color:black'> Add New Apartment.</span> Select the store, then search for the apartment to which you wish to make deliveries. Once you find the apartment, select it. You will notice that the apartment name, city, and pin code fields have been automatically filled by the system. Label the delivery zone and add delivery fees. Click on <span style='color:black'> Add Group </span>to save the changes.",
  "define.point10":
    "You can also add multiple apartments using the <span style='color:black'> Add Apartment</span> option as shown below.",
  "how.will.this.change.reflect":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>How will this change reflect on my website?",
  "reflect.point1":
    "When the user visits your online store, on the home page, the user will be able to see a Location tab on the header section of your home page. The user can enter the location at which they would like to receive their deliveries, and according to the delivery zone criteria set, the system will check whether delivery can be done in this area or not.",
  "reflect.point2":
    "When your customer signs in to your online store with their email ID, they will be required to add their respective address in the Profile section.",
  "reflect.point3":
    "If the address added does not match any of the criteria defined for delivery zones, an error message will be displayed as shown below",
  "reflect.point4":
    "If the address added matches any one of the criteria defined for the delivery zones, it will automatically populate the City and Pincode fields. The user can save the address by clicking on the <span style='color:black'>Add Address</span> option and continue shopping.",
  "reflect.point5":
    "If the customer directly starts adding products to their checkout cart, when they click on <span style='color:black'> Add to Cart </span>, a dialogue box will appear requesting the user to add the location.",
  "uninstall.heading":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>What happens when I uninstall this extension?",
  "uninstall.point1":
    "You can no longer define delivery areas for your online store’s delivery service.",
  "uninstall.point2":
    "The system will assume that your store delivers everywhere with no boundaries or area limitations.",
  "uninstall.point3":
    "You will not be able to define delivery fees for all the deliveries that you accept via the online store.",
  "role.responsibility.of.network.participants":
    "Roles and Responsibilities of Network Participants",
  "role.responsibility.of.network.participants.description":
    "The objective of this blog is to explain the different roles and responsibilities of Network Participants (NP) as part of ONDC. For more information on ONDC, refer to <span style='display:none' class='ondc-post'; >Introduction to ONDC blog post</span> <a href='/blog/introduction-to-ondc' style='color:#4ab819' class='ondc-post-link'; >Introduction to ONDC blog post</a> for the same.",
  "role.responsibility.of.network.participants.more.description.para1":
    "Let’s first understand what an e-commerce purchase flow looks like in a marketplace. Here are the following steps that take place while shopping in a marketplace - <br>1. User searches/ discovers product(s)<br>2. Initiate purchase<br>3. Make payment<br>4. Purchase confirmation<br>5. Product delivery<br>6. Give review/ request return or refund",
  "role.responsibility.of.network.participants.more.description.para2":
    "As you may have read in the <a href='/blog/introduction-to-ondc' style='color:#4ab819';>Introduction to ONDC </a>blog, we have explained the major participants of the Network (Buyer NP, Seller NP and Logistics Service Provider NP) and their roles briefly. These roles ensure that the e-commerce flow in a marketplace is taken care of at an individual level and no one player has to take responsibility for the entire purchase/ sale cycle.",
  "role.responsibility.of.network.participants.more.description.para3":
    "As a seller, it is very important that you understand your role in the Network, especially in collaboration with a Buyer NP. Let’s take a closer look at what a Buyer and Seller NP’s responsibilities look like. Below is a table showing a compared list of responsibilities that both parties are capable of handling-",
  "role.responsibility.imgcontent.one":
    "<div style='display:flex;flex-direction:row;justify-content:center;'>Seller NP</div>1. Onboarding Sellers<br>",
  "role.responsibility.imgcontent.two": "2. Catalog Management<br>",
  "role.responsibility.imgcontent.three":
    "3. Order, Dispatch and Returns Management<br>",
  "role.responsibility.imgcontent.four": "4. Packaging<br>",
  "role.responsibility.imgcontent.five": "5. Customer Support<br>",
  "role.responsibility.imgcontent.six": "6. Operations Planning<br>",
  "role.responsibility.imgcontent.seven": "7. Improving Buyer Experience<br>",
  "role.responsibility.imgcontent.eight": "8. Payments and Settlements",
  "role.responsibility.imgcontent2.one":
    "<div style='display:flex;flex-direction:row;justify-content:center;'>Buyer NP </div>1. Improving buyer experience(UI/UX) on user application<br>",
  "role.responsibility.imgcontent2.two": "2. Operations Planning <br>",
  "role.responsibility.imgcontent2.three": "3. Customer Support<br>",
  "role.responsibility.imgcontent2.four": "4. Payments and Settlements<br>",
  "role.responsibility.para.1.description":
    "Let’s try and understand these tasks/responsibilities in depth<br><span style='color: #0A0A0A;'>1. Onboarding sellers- </span>The seller NP is responsible for bringing in actual sellers who will sell their products through the seller application",
  "role.responsibility.para.2.description":
    "<span style='color: #0A0A0A;'>2. Catalog Management- </span>The seller is responsible for creating a digital inventory of the products for sale via the seller application",
  "role.responsibility.para.3.description":
    "<span style='color: #0A0A0A;'>3. Order, Dispatch and Returns Management- </span>From managing order status, purchase cancellations, inventory availability and return requests & logistics, the seller NP is responsible for it",
  "role.responsibility.para.4.description":
    "<span style='color: #0A0A0A;'>4. Packaging- </span>From the material used for storage to the quantity of packaging material, everything is managed at the Seller NP node by the actual seller.",
  "role.responsibility.para.5.description":
    "<span style='color: #0A0A0A;'>5. Customer Support- </span>All customer-related issues (for example, delayed delivery, product defects, wrong products delivered, etc) are resolved by the Seller NP.",
  "role.responsibility.para.6.description":
    "<span style='color: #0A0A0A;'>6. Operations Planning- </span>In order for the e-commerce flow to be functioning smoothly, both Seller and Buyer NPs need to have a transparent channel of communication, to ensure order execution is 100%. This often happens using custom API calls.",
  "role.responsibility.para.7.description":
    "<span style='color: #0A0A0A;'>7. Improving Buyer Experience- </span>From improving UI/UX and making sure the buyer application is feature rich, with exhaustive search capabilities and easily discoverable products, this is the Buyer NP share of responsibility.",
  "role.responsibility.para.8.description":
    "<span style='color: #0A0A0A;'>8. Payments and Settlements- </span>Once an order has been delivered, it's finally time for dues settlement for both the seller and buyer parties involved. Hence there is a shared responsibility between both parties.",
  "role.responsibility.para.9.description":
    "As explained above, you can clearly see the fair distinction between the roles and responsibilities of the seller and buyer parties. For the responsibilities that are shared, proper contracts are established to maintain business transparency. Since other network participants are free to draw up their own terms and conditions, they can always negotiate terms with other willing parties present in ONDC Network.",
  "what.is.delivery/pickup.slot.management":
    "What is Delivery/ Pickup Slot Management?",
  "what.is.delivery/pickup.slot.management.description":
    "Delivery/ Pickup slot management for e-commerce stores involves organizing and scheduling customer orders within specific time slots. It offers customer convenience, resource optimization, reduced congestion, faster deliveries, improved inventory management, real-time updates, communication, analytics, and customization. It's a vital aspect of modern e-commerce operations, enhancing the customer experience and improving overall efficiency.",
  "why.do.i.need.this.extension.delivery.pickup.slot.qn1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Why do I need this for my online store?</span>",
  "delivery.pickup.slot.ans1":
    "1. <span style='margin-left:.25rem;'>If you own a hyperlocal store, setting up deliveries and pickups, especially for an F&B or grocery business owner, is essential to the business growth.</span> <br> 2. <span style='margin-left:.25rem;'>This set of extensions will help you manage and set up slot timings for deliveries and pickups.</span><br> 3. <span style='margin-left:.25rem;'>This feature will also allow you to define rules surrounding slot capacities, in which time windows you will be able to set up deliveries accordingly, etc.</span><br> 4. <span style='margin-left:.25rem;'>These extensions will also help you set slots and handling charges accordingly as well.</span>",
  "what.extension.will.i.need.to.manage.delivery.pickup.slot.qn2-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>What extensions will I need to manage delivery/ pickup slots?</span>",
  "delivery.pickup.slot.ans2":
    "In Zopping, you will require 3 extensions to be installed for your store.<br><span style='color:#181912'>1. </span><span style='margin-left:.25rem;color:#181912'>Delivery/ Pick-up Slot Set-up - </span>this extension will help you configure slot timings for delivery/ pickup for your working hours on business days. <br><span style='color:#181912'>2. </span><span style='margin-left:.25rem;color:#181912'>Delivery/ Pick-up Slot Charges - </span>this extension will help you configure separate slot charges if needed for every delivery/ pickup slot.<br> <span style='color:#181912'>3. </span><span style='margin-left:.25rem;color:#181912'>Slot Capacity Set-up - </span>this extension will help you define a slot capacity for every delivery/ pickup slot planned for the business day.",
  "delivery.pickup.slot.prerequisites.step.1":
    "You need to have a Google API Key. Please refer <a style='color:#00BE00' href='https://developers.google.com/maps/documentation/javascript/get-api-key' target='_blank'>here</a> to learn more about the same and how to get one.",
  "delivery.pickup.slot.prerequisites.step.2":
    "Once you have procured one, go to your Dashboard. Navigate to <span style='color:#0A0A0A'>Settings > API Integration. </span>Fill in the Google Maps API Key row and click on <span style='color:#0A0A0A'>Save.</span>",
  "delivery.pickup.slot.prerequisites.step.4":
    "Navigate to <span style='color:#0A0A0A'>Settings > Order Configuration. </span>Set the Order Type as Delivery or Pickup or both. ",
  "how.to.install.delivery.pickup.slot.extension":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>How to Install Delivery/ Pickup Slot Setup on Zopping?</span>",
  "delivery.pickup.slot.extension.setup.step1":
    "Navigate to the <span style='color:#0A0A0A'>Settings > Extensions > Delivery section. </span>Here you will find the <span style='color:#0A0A0A'>Delivery/ Pick-up Slot Set-up</span> extension window as shown below.",
  "delivery.pickup.slot.extension.installation.note":
    "Please note that, after the configurations are set up for the <span style='color:#0A0A0A'>Delivery / Pick-up Slot Set-up</span> extension, only then will you be able to install the <span style='color:#0A0A0A'>Delivery/ Pick-up Slot Charges</span> and the <span style='color:#0A0A0A'>Slot Capacity Set-up</span> extensions.",
  "how.do.i.define.delivery.pickup.slot":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem;margin-bottom: 30px'>How do I define Slot charges for delivery/ pickup?</span>",
  "how.do.i.define.delivery.pickup.slot.my.store":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem;margin-bottom: 30px'>How do I define delivery/ pickup slots for my store(s)?</span>",
  "delivery.pickup.slot.my.store.define.point1":
    "Now go to the <span style='color:#0A0A0A'>Extensions > Installed</span> section. You will find the <span style='color:#0A0A0A'>Delivery/ Pick-up Slot Set-up</span> extension in this window. Click on the <span style='color:#0A0A0A'>Configure</span> option.",
  "delivery.pickup.slot.my.store.define.point2":
    "You can see a page called <span style='color:#0A0A0A'>Slots</span> on your screen. This page has two sections named <span style='color:#0A0A0A'>Delivery and Pickup.</span> Click on the <span style='color:#0A0A0A'>Add Slot</span> button on the right.",
  "delivery.pickup.slot.my.store.define.point3":
    "A small window will appear titled <span style='color:#0A0A0A'>Add Slot.</span> Here, two options will be presented to you, namely Time and ASAP.<ul class='blog-list'><li><div class='main-point'><div class='points'></div><span>The Time option allows you to select a custom window of time (where you can define a start time and an end time) for deliveries/ pickup requests to be accommodated.  Ex: 3:00 PM to 3:30 PM.</span></div></li><li><div class='main-point'><div class='points'></div><span>The ASAP (As Soon As Possible) option allows you to define a response time from the time of order placement, for the delivery/ pickup requests to be catered to.</span></div></li></ul>",
  "delivery.pickup.slot.my.store.define.point4":
    "Click on the Time option, then define a start and end time for the slot. Click on <span style='color:#0A0A0A'>Save.</span>",
  "delivery.pickup.slot.my.store.define.point5":
    "Similarly, you can also add a limit to response time (in minutes) for the ASAP slot timings as well.",
  "delivery.pickup.slot.my.store.define.point6":
    "Once these slots are saved in the system, you can define the number of days the slots should be shown to the customers on your website. You can also choose the business timings in which these delivery slot timings will be open for customers. ",
  "delivery.pickup.slot.my.store.define.point7":
    "You can follow the same steps for the Pickup section as well.",
  "delivery.pickup.slot.my.store.define.point8":
    "After adding all the required information, click on <span style='color:#0A0A0A'>Save.</span>",
  "delivery.pickup.slot.define.point1":
    "Now, you can navigate back to the Extensions section and install the <span style='color:#0A0A0A'>Delivery/ Pick-up Slot</span> Charges and the <span style='color:#0A0A0A'>Slot Capacity Set-up</span> extensions.",
  "delivery.pickup.slot.define.point2":
    "Now go to the <span style='color:#0A0A0A'>Extensions > Installed</span> section. You will find the <span style='color:#0A0A0A'>Delivery/ Pick-up Slot Charges</span> extension in this window. Click on the <span style='color:#0A0A0A'>Configure</span> option.",
  "delivery.pickup.slot.define.point3":
    "You can see a page called <span style='color:#0A0A0A'>Slot Charges</span> on your screen. Here, 3 sections will be presented to you, namely Surcharge, Order Discount, and Handling Discount. Please refer below to what these charges are- <br> <ul class='blog-list'><li><div class='main-point'><div class='points'></div><span><span style='color:#0A0A0A'>Surcharge-</span> This cost is added when a surplus amount needs to be charged for delivering an order in the given slot. For example, if the vendor is usually busy handling a huge amount of orders, the vendor can add a surcharge to ensure order delivery.</span></div></li><li><div class='main-point'><div class='points'></div><span><span style='color:#0A0A0A'>Order Discount- </span>This cost is deducted from the overall order amount when any order is placed at a given slot. This is usually done to increase order traffic at a particular period.<span></div></li><li><div class='main-point'><div class='points'></div><span><span style='color:#0A0A0A'>Handling Discount- </span>This cost is deducted from the overall order amount to eliminate extra shipping costs.</span></div></li></ul>",
  "delivery.pickup.slot.define.point4":
    "In each section, on the left side, you will see the Slots column. Here you will see all the slots you had defined using the <span style='color:#0A0A0A'>Delivery/ Pick-up Slot Set-up</span> extension. For each slot, you can add a default amount charged for either delivery or pickup. Click on the “+” symbol next to the Delivery heading and a row will appear. Enter the relevant amount that can be charged for each delivery and pickup scenario and press Enter/ Return to save the value.",
  "delivery.pickup.slot.reflect.point1":
    "Now go to the <span style='color:#0A0A0A'>Extensions > Installed</span> section. You will find the <span style='color:#0A0A0A'>Slot Capacity Set-up</span> extension in this window. Click on the <span style='color:#0A0A0A'>Configure</span> option.",
  "delivery.pickup.slot.reflect.point2":
    "You can see a page called <span style='color:#0A0A0A'>Capacity Planning</span> on your screen. Click on the <span style='color:#0A0A0A'>Add Rule</span> button on the right.",
  "delivery.pickup.slot.reflect.point3":
    "A window will appear, where you can define certain parameters such as type of order completion, which delivery zone* you wish to define this rule for, select the concerned delivery/ pickup slot, add a cutoff time (cutoff time refers to the window of time before the slot defined, where the vendor will stop accepting delivery requests for the slot chosen by the customer), and define the slot capacity for this particular scenario defined.",
  "delivery.pickup.slot.reflect.point4":
    "For example, in the above image, the rule is defined for all deliveries that the vendor will receive on all business days, for the slot of 4 p.m. to 4:30 p.m., with a cutoff time of 30 mins (0.5h), the slot capacity will be 10.",
  "delivery.pickup.slot.reflect.point5":
    "In a scenario where even after the cutoff time has been initiated, and the slot capacity still isn't at its maximum capacity, the system will still not accept delivery requests for that particular slot.",
  "delivery.pickup.slot.reflect.point6":
    "Click on <span style='color:#0A0A0A'>Save</span> to save all these changes in the system. ",
  "how.will.this.change.affect":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>How will this change affect my website?</span>",
  "delivery.pickup.slot.affect.point3":
    "On the checkout page, the user will be able to see some sections, which are used to collect relevant information for order completion.",
  "delivery.pickup.slot.affect.point4":
    "Here, 2 sections regarding delivery/ pickup information will also be present, where the user can select what kind of delivery mode they prefer (delivery/ pickup), select the delivery address accordingly, and pick a slot for delivery completion. According to the charges defined, the slot charges column on the right side of the page will get updated.",
  "delivery.pickup.slot.affect.point5":
    "Once the payment mode is selected, the user can proceed to place the order.",
  "delivery.pickup.slot.uninstall.point1":
    "No slots will be allotted for deliveries or pickups anymore.",
  "delivery.pickup.slot.uninstall.point2":
    "No extra charges can be added to accommodate special slot delivery requests.",
  "delivery.pickup.slot.uninstall.point3":
    "No supervision or management can be done on how many delivery requests can be accepted by the business owner.",
  "how.to.define.slot.capacity.delivery/pickup":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>How to define slot capacity for delivery/pickup?",
  "what-is-referral-management": "What is Referral Management?",
  "what-is-referral-management.description":
    "Referral management is an extension in Zopping that enables you to run a referral program for your online store. Referrals encourage and reward satisfied and loyal customers to refer new customers to your online store. It involves sharing referrals and rewarding those who refer successfully. By leveraging incentives, referral management helps online businesses organically expand their customer base and increase sales.",
  "what-is-referral-management.slot.qn1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Why do I need this Extension?</span>",
  "referral-management.slot.ans1":
    "1. <span style='margin-left:.25rem;'>This extension will help you define a referral bonus for users who will bring footfall to your online store</span> <br> 2. <span style='margin-left:.25rem;'>This extension will also help you set up a signup bonus for first-time shoppers on your online store.    </span><br> 3. <span style='margin-left:.25rem;'>The addition of this extension will help you increase initial traction for your website by incentivizing the whole shopping process.</span>",
  "referral-management.slot.prerequisites.step.1":
    "Please ensure the <span style='color:#0A0A0A'>Customer Wallet Support extension</span> is installed and configured on your website.",
  "what-is-referral-management.slot.qn2-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How do I generate referral codes for my website users?</span>",
  "referral-management.slot.prerequisites.step.3":
    "Navigate to the <span style='color:#0A0A0A'>Settings > Extensions > Marketing section</span>. Here you will find the Referral Management extension window as shown below.",
  "referral-management.slot.prerequisites.step.4":
    "Click <span style='color:#0A0A0A'>Install</span> on the window, and add the extension to your website.",
  "referral-management.slot.prerequisites.step.5":
    "After installing the <span style='color:#0A0A0A'>Referral Management extension</span>, navigate to the <span style='color:#0A0A0A'>Extensions > Installed</span> section. You will find the same extension in this window. Click on the <span style='color:#0A0A0A'>Configure</span> option.",
  "referral-management.slot.prerequisites.step.6":
    "Once you land on the Referral Management page, you will see 2 sections of <span style='color:#0A0A0A'>Referrer</span> and <span style='color:#0A0A0A'>Referee</span>.<ul class='blog-list'><li><div class='main-point'><div class='points'></div><span><span style='color:#0A0A0A'>Referrer</span> refers to the existing customer whose credentials are already registered with the online store and who wants to refer your online store to his/ her friend/ family.</span></div></li><li><div class='main-point'><div class='points'></div><span><span style='color:#0A0A0A'>Referee</span> specifies the incoming new customer who is yet to register with the online store.</span></div></li></ul>",
  "referral-management.slot.prerequisites.step.7":
    "In the Referrer section, you can add a <span style='color:#0A0A0A'>Referral</span> bonus either in percentage or a flat amount. If you define a percentage, click on the ‘%’ option. When you do so, another option in the same section will appear, named <span style='color:#0A0A0A'>Maximum Limit</span>. This will help you define an upper-limit discount for the referral bonus to be added to the purchase.",
  "referral-management.slot.prerequisites.step.8":
    "Please note that if the referee places an order, and the referral bonus applicable exceeds the percentage limit defined here, the value defined for the <span style='color:#0A0A0A'>Maximum Limit</span> will be prioritized by the system and will be applied at the time of order completion.",
  "referral-management.slot.prerequisites.step.9":
    "In the Referee section, you can add a <span style='color:#0A0A0A'>Signup bonus</span>. This will get credited into the wallet of the referee upon successfully signing up and this money can be used for immediate purchases on your online store.",
  "referral-management.slot.prerequisites.step.10":
    "After adding all the required information, click on <span style='color:#0A0A0A'>Save</span>.",
  "referral-management.slot.affect":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How will this change affect my online store?</span",
  "referral-management.slot.affect.point1":
    "When an existing user logs in to your online store, in the profile section, you will see an option to <span style='color:#0A0A0A'>Refer a Friend</span>.",
  "referral-management.slot.affect.point2":
    "When the users select this option, a pop-up will open displaying the referral code.",
  "referral-management.slot.affect.point3":
    "The user can then send this referral code to any newcomer to the website and they can avail of this discount on their first purchase.",
  "referral-management.slot.affect.point4":
    "When the newcomer signs up for the first time, an option to enter a referral code will be shown. Here, they can enter the referral code with their credentials and sign up at the store.",
  "referral-management.slot.affect.point5":
    "Once they sign up, the referral bonus will be reflected in the newcomer’s store wallet. The user needs to go to <span style='color:#0A0A0A'>Profile > Wallet</span> and there will be added credit balance to the wallet, which the user can avail in their purchases.",
  "referral-management.slot.note.point1":
    "A single referral code can be handed over to multiple users. All of their purchases and the subsequent referral bonuses will be credited accordingly.",
  "referral-management.slot.note.point2":
    "The receivers of the referral codes can use their signup bonus preferably on their first purchase",
  "referral-management.slot.note.point3":
    "The referrer will receive their referral bonus only after the referee makes a purchase. The referral bonus should reflect on the referrer’s wallet within a couple of days of the referee’s order completion",
  "referral-management.slot.uninstall.point1":
    "Your customers can no longer see their unique referral code.",
  "referral-management.slot.uninstall.point2":
    "New users who sign up can no longer use the referral code.",
  "referral-management.slot.uninstall.point3":
    "Existing cashback in the customer wallet can be continued to be used for future purchases.",
  "note.heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Note</span>",
  "what-are-reviews-ratings": "What are Reviews and Ratings?",
  "what-are-reviews-ratings.description":
    "Reviews and Ratings help a business owner receive active feedback and suggestions regarding products sold in their online store. Customer reviews influence trust, purchasing decisions, and the business's overall reputation. Positive feedback builds credibility, attracts new customers, and provides valuable insights for improvement.",
  "what-are-reviews-ratings.slot.qn1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Why do I need the Reviews and Ratings Extension?</span>",
  "reviews-ratings.slot.ans1":
    "1. <span style='margin-left:.25rem;'>This extension will allow your customers to rate and review your products and the order experience</span> <br> 2. <span style='margin-left:.25rem;'>The more reviews and ratings are recorded for your products, the more social proof you can build for your online store, influencing incoming customers to buy from your website.</span><br> 3. <span style='margin-left:.25rem;'>The more positive reviews and ratings are submitted for products in your online store, the more trust and credibility you add to your business.</span><br> 4. <span style='margin-left:.25rem;'>Reviews also improve your SEO rankings.</span>",
  "reviews-ratings.slot.prerequisites.step.1":
    "There are no prerequisites required to install this extension on your website.",
  "what-are-reviews-ratings.slot.qn2-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How to install Reviews and Ratings Extension on Zopping</span>",
  "reviews-ratings.slot.prerequisites.step.2":
    "Navigate to the <span style='color:#0A0A0A'>Settings > Extensions > Marketing</span> section. Here you will find the <span style='color:#0A0A0A'>Reviews and Ratings</span> extension window as shown below.",
  "reviews-ratings.slot.prerequisites.step.22":
    "Click <span style='color:#0A0A0A'>Install</span> on the window, and add the extension to your website.",
  "reviews-ratings.slot.affect":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How will this change affect my online store?</span",
  "reviews-ratings.slot.affect.note1":
    "There are <span style='color:#0A0A0A'>2 types</span> of reviews that a customer can submit on your website; <span style='color:#0A0A0A'>Order Review</span> and <span style='color:#0A0A0A'>Product Review</span>",
  "reviews-ratings.slot.affect.point2":
    "In the profile section, select the <span style='color:#0A0A0A'>Orders</span> option. Here the user will be able to find all the orders they have placed on your website. The user can click on the <span style='color:#0A0A0A'>Write Review</span> option to give reviews and ratings for the order placed.",
  "reviews-ratings.slot.affect.note2":
    "Note- the user will only be able to give reviews for completed orders",
  "reviews-ratings.slot.affect.point3":
    "Once landed on the Rating and Review page, the user can now give feedback for both the order and products delivered here. Click on <span style='color:#0A0A0A'>Submit</span> to record the review and rating.",
  "reviews-ratings.slot.affect.point4":
    "The store owner can also view their customers’ feedback in the <span style='color:#0A0A0A'>Reviews</span> section of the dashboard.",
  "reviews-ratings.slot.uninstall.point1":
    "All your customers' ratings and reviews will no longer be visible",
  "reviews-ratings.slot.uninstall.point2":
    "You or your customers will not be able to see the ratings and reviews for the products in your online store",
  "what-is-customer-wallet": "How to use Customer Wallet extension in Zopping?",
  "what-is-customer-wallet.description":
    "A customer wallet in your online store allows users to store money digitally in their wallets for future purchases. This makes transactions quicker, more secure, and encourages repeat business. It's a user-friendly feature that benefits both customers and the online business by reducing checkout hassles.",
  "what-is-customer-wallet.qn1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:2rem'>Why do I need the Customer Wallet Support Extension?</span>",
  "customer-wallet.ans1":
    "<ul style='padding-inline-start: 15px;margin:0px'><li style='color: #0A0A0A;margin-bottom:3px;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>This extension will help each of your customer have a digital wallet for your shopping on your store.</span></li ><li style='color: #0A0A0A; margin-bottom:3px;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>The customer will be able to add money to their wallet which can be used for future purchases.</span></li><li style='color: #0A0A0A;margin-bottom:3px;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>You can also offer cashback to your customers on repeated purchases which will incentivize for repeat purchases.</span></li><li style='color: #0A0A0A;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>It helps you run a referral program wherein you can reward your loyal customers who refer your store to their friends and family. Learn how Referral.</span></li></ul >",
  "what-is-customer-wallet.prerequisites.step.1":
    "You need to have the Online Payment extension installed for your online store and have atleast one payment gateway configured. Refer this <b><a href='https://zopping.com/blog/how-to-accept-online-payments-from-customers-for-their-orders-on-zopping'target='_blank' rel='noopener noreferrer' style='color:#00BE00';> article</a></b> to know more about how to install the online payment extension and how to procure payment gateway API credentials. ",
  "what-is-customer-wallet.prerequisites.step.2":
    "<ul><li style='color: #0A0A0A;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>Navigate to the <span style='color: #0A0A0A; '>Settings > Extensions > Payments</span>  section</span>.</li ><li style='color: #0A0A0A;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>Here you will find the <span style='color: #0A0A0A; '>Customer Wallet Support extension </span>. Click on <span style='color: #0A0A0A; '>Install</span> and add the extension to your website.</span></li></ul > ",
  "customer-wallet-support.qn2-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How to install Customer Wallet Extension on Zopping</span>",
  "customer-wallet-support.qn3-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How can I enable Customer Wallet for my online store?</span>",
  "customer-wallet-support.define1":
    "After installing the Customer Wallet Support, navigate to the <span style='color:#0A0A0A'>Extensions > Installed .</span>section. You will find the same extension in this window. Click on the <span style='color:#0A0A0A'>Configure</span> option.",
  "customer-wallet-support.define2":
    "Once you land on the Wallet Configuration page, you can see 3 different fields. We will walk you through what each field signifies and how you can configure the values for the same.",
  "customer-wallet-conflagration.notes.point1":
    "<span style='color: #0A0A0A;'>Note- </span>You can continue configuring details in this page only if you are interested in building a loyalty program for your customers. If not, the customers can continue using their wallet for their individual purchases.",
  "customer-wallet-configuration.notes.point2":
    "The <span style='color: #0A0A0A;'>Expires After</span> field defines the number of days the credit amount is available in your wallet. Beyond those many days, it expires.  Example: Let's say you have defined 365 days in this field. If a cashback has been offered to your customer at the time of purchase, the cashback will be redeemable for 365 days.",
  "customer-wallet-configuration.notes.point3":
    "In the <span style='color: #0A0A0A;'>Cashback Percentage</span> field, you can define the percentage of the purchase amount that should be deposited in the customer’s wallet after the transaction for the same is completed.",
  "customer-wallet-configuration.notes.point4":
    "<span style='color: #0A0A0A;'>Note-</span> The cashback percentage defined here is valid for all purchases made with the customer’s account.",
  "customer-wallet-configuration.notes.point5":
    "In the <span style='color: #0A0A0A;'> Days After Order Completion for Cashback</span> field, you can define the days after which the cashback amount should be credited to the customer’s wallet.",
  "customer-wallet-configuration.notes.point6":
    "Click on <span style='color: #0A0A0A;'>Save</span> to save the changes.",
  "how-do-i-affect-online-store":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:3rem'>How will this change affect my online store?</span>",
  "customer-wallet-configuration.notes.point7":
    "When an existing user logs in to your online store, in the profile section, the user will see a <span style='color: #0A0A0A;'>Wallet</span> option.",
  "customer-wallet-configuration.notes.point8":
    "When the user selects the <span style='color: #0A0A0A;'>Wallet</span> option, they will be redirected to their wallet page. If this is their first time visiting, the screen will show the following page.",
  "customer-wallet-configuration.notes.point9":
    "Click on the <span style='color: #0A0A0A;'>Add Amount</span> option. This will lead the user to the <span style='color: #0A0A0A;'> Recharge</span> window. The user can now add an amount that they need to add to the wallet and click on the <span style='color: #0A0A0A;'> Recharge</span> button. After completion of the transaction via a payment gateway, the amount will be reflected in the wallet.",
  "customer-wallet-configuration.notes.point10":
    "The customer will now be able to see their wallet money, cashback, and referral bonus amounts on their <span style='color: #0A0A0A;'>Wallet</span> page in their profile.",
  "customer-wallet-configuration.notes.point11":
    "The store owner will also be able to view the wallet information of any customer registered with their online store. Navigate to <span style='color: #0A0A0A;'> Customer > View Customer > Wallet</span>. Here you will be able to see all the wallet money, cashback, and referral bonus amounts that are redeemable by the customer.",
  "customer-wallet-configuration.notes.point12":
    "<span style='color: #0A0A0A;'>Note-</span> There are 2 types of funds that can be deposited in the customer wallet-",
  "customer-wallet-configuration.notes.point13":
    "<span style='color: #0A0A0A;'>Wallet Money-</span> this is the amount that the customer has deposited in their wallet using a payment gateway. They can use this amount for their purchases. This amount does not expire and stays in the wallet.",
  "customer-wallet-configuration.notes.point14":
    "<span style='color: #0A0A0A;'>Cashback- </span> this is the amount rewarded to the customer by the store owner. This can be in the form of cashbacks offered on purchases, referral bonus or signup bonuses.",
  "customer-wallet.uninstall.point1":
    "<ul style='padding-inline-start: 15px;margin:0px;'><li style='color: #0A0A0A;margin-bottom:3px;line-height:24px;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>Customers will not be able to view and recharge Wallet and use the Wallet Money to pay for orders. All existing data will be lost.</span></li ><li style='color: #0A0A0A; margin-bottom:3px;line-height:24px;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;'>Customers will not be able to view and receive Cashback in their Wallet and use it to pay for orders and receive Cashback and use it to pay for orders.</span></li></ul > ",
  "what-are-custom-fields": "What are Custom Fields?",
  "what-are-custom-fields.description":
    "Custom fields are extra fields that the store owner can configure in order to capture additional information about the product/ order/ customer/ store/ address, etc.",
  "what-are-custom-fields.slot.qn1-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Why do I need the Custom Fields Extension?</span>",
  "custom-fields.slot.ans1":
    "Adding custom fields to a product allows you to share additional information about your products like weight, dimensions, instruction manual, brochure, or any custom product properties and details that might be useful to the buyers.<br><br>Adding custom fields to an order allows you to capture additional information about the order. For example, you could ask the buyer if they wish to gift wrap their order or not/ seek any additional packing instructions, order customizations/ upload doctor prescriptions in case you are selling medicines, etc.<br><br>Adding custom fields to a customer signup form would be useful if you wish to capture any additional information from the customer at the time of signup. For example, you could ask about the customer's age/ GST or VAT number/ gender, etc.<br><br>Adding custom fields to address can help you capture further information about the delivery location. For example, you could capture the house number/ block number, etc.",
  "custom-fields.slot.prerequisites.step.2":
    "Navigate to <span style='color:#0A0A0A'>Settings > Extensions > Online Store section</span>. Here you will find the <span style='color:#0A0A0A'>Custom Fields</span> extension. Click on <span style='color:#0A0A0A'>Install</span> and add the extension to your website.",
  "custom-fields.slot.qn2-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How to install Custom Fields Extension on Zopping</span>",
  "custom-fields.slot.qn3-heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How can I add custom fields for my online store?</span>",
  "custom-fields.slot.define1":
    "After installing the custom fields extension, navigate to the <span style='color:#0A0A0A'>Extensions > Installed section.</span> You will find the same extension in this window. Click on the <span style='color:#0A0A0A'>Configure</span> option",
  "custom-fields.slot.define2":
    "Once you land on the Custom Fields page, you will be shown a few tabs like <span style='color:#0A0A0A'>Order, Customer, Product, Store, Address, and Offer</span>. We will walk you through every step to add custom fields for each tab.",
  "custom-fields.slot.define3":
    "Select the <span style='color:#0A0A0A'>Order</span> tab. Click on the <span style='color:#0A0A0A'>Add Key</span> option. You will see a few filler fields. In the <span style='color:#0A0A0A'>Name</span> field, fill in the name of the custom field.",
  "custom-fields.slot.define4":
    "In the <span style='color:#0A0A0A'>Type</span> field, select the type of data that the system should accept for this custom field.",
  "custom-fieldss.slot.define4.slot": "Examples of custom field Types:",
  "custom-fields.slot.define4.point1":
    "<span style='color:#0A0A0A'>Enum:</span> You can enter multiple values and the user has to choose from one among them. The values can be numbers/ strings/ text.",
  "custom-fields.slot.define4.point2":
    "<span style='color:#0A0A0A'>MultiValued Enum:</span> You can enter multiple values and the user can choose one or more than one value. The values can be numbers/ strings/ text",
  "custom-fields.slot.define5":
    "You will be able to see 2 checkboxes as well, namely <span style='color:#0A0A0A'>Private</span> and <span style='color:#0A0A0A'>Mandatory</span>. Marking a custom field <span style='color:#0A0A0A'>Private</span> indicates that the custom field can be edited and viewed only by the store owner. <span style='color:#0A0A0A'>Mandatory</span> signifies that without this custom field specified, the associated task will not be completed.",
  "custom-fields.slot.define6":
    "After adding all the required information, click on <span style='color:#0A0A0A'>Save</span> to save changes.",
  "custom-fields.slot.define7":
    "Follow the same process for all the tabs and add relevant custom fields, wherever necessary.",
  "custom-fields.slot.affect":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>How will this change affect my online store?</span",
  "custom-fields.slot.affect.point1":
    "<span style='color:#0A0A0A'>Order-related custom fields:</span> When a customer places an order on your website, the customer will be able to see the public order-related custom fields on the checkout page under the <span style='color:#0A0A0A'>Additional Details</span> section.",
  "custom-fields.slot.affect.point2":
    "<span style='color:#0A0A0A'>Customer-related custom fields:</span> When a customer signs up on your website, the customer will be able to fill out the additional information in the customer-specific custom fields in the sign-up pop-up.",
  "custom-fields.slot.affect.point3":
    "<span style='color:#0A0A0A'>Product-related custom fields</span> You can add custom product-related information by navigating to <span style='color:#0A0A0A'>Products > Add Products</span>. Scroll down to the <span style='color:#0A0A0A'>Other Details</span> section right beside the Description. You can find the custom field defined using the extension here.",
  "custom-fields.slot.affect.point4":
    "All the information defined on the product page will show up on the corresponding product window on your website under the <span style='color:#0A0A0A'>Highlights/ Additional Information</span> section.",
  "custom-fields.slot.affect.point5":
    "<span style='color:#0A0A0A'>Store-related custom fields</span> The store-related custom fields can be found at <span style='color:#0A0A0A'>Settings> Store > Edit Store page</span>. The business can add relevant information here",
  "custom-fields.slot.affect.point6":
    "<span style='color:#0A0A0A'>Address-related custom fields</span> For address-related information, the system can capture additional information regarding the same. Navigate to <span style='color:#0A0A0A'>Customers > View Customer Details > Address</span>. Click on <span style='color:#0A0A0A'>Add Address</span>. You will find the additional custom fields here along with the mandatory ones <span style='color:#0A0A0A'>(address, pin code, and city)</span>",
  "custom-fields.slot.uninstall.point1":
    "You will no longer be able to add or edit custom fields",
  "custom-fields.slot.uninstall.point2":
    "Users/ customers will not be able to fill custom fields",
  "custom-fields.slot.uninstall.point3":
    "All the custom field data captured for orders/ products/ customers/ addresses/ stores will no longer be available",
  "what.is.an.abandoned.cart":
    "How to use Abandoned Cart extension in Zopping?",
  "what.is.an.abandoned.cart.meta.description":
    "When a user adds products to their shopping cart but for some reason they did not proceed to checkout, this scenario is referrred as Abandoned Cart.",
  "what.is.an.abandoned.cart.description":
    "When a user adds products to their shopping cart but for some reason they did not proceed to checkout, this scenario is referrred as Abandoned Cart. Lot of ecommerce companies face this issue and it is a prevalent cause of concern to brands and retailers. Zopping allows you to retarget and remind customers to visit your online store and complete the purchase. This is achieved through the Abandoned Cart extension.",
  "what.is.an.abandoned.cart.qn1.heading":
    "<span style='color: #0A0A0A; font-size: 20px; line-height:1.8rem'>Why do I need the Abandoned Cart Extension?</span>",
  "what.is.an.abandoned.cart.ans1":
    "This extension will allow you to notify your customers to resume their shopping experience if there are any products added to the cart but have not made the purchase.",
  "what.is.an.abandoned.cart.prerequisites.step.1":
    "If you wish to send out notifications via email, you need to have a SMTP configuration set up for your Zopping account. Follow this blog to know more about <a href='/blog/how-to-set-up-Email-and-smtp-configurations-for-your-online-store-on-zopping/' target='_blank' rel='noopener noreferrer' style='color: #4AB819'>how to setup SMTP for your online store.</a>",
  "what.is.an.abandoned.cart.prerequisites.step.2":
    "If you wish to send out notifications via SMS, you need to have an SMS gateway (eg, twilio, Whispir, TextMagic, etc.) set up for your Zopping Account. Navigate to Communication > SMS and select and configure the service provider accordingly.",
  "what.is.an.abandoned.cart.prerequisites.step.3":
    "If you wish to send out push notifications for your mobile app users, you need to have a <a href='/admin/catalogue/products/add' target='_blank' rel='noopener noreferrer' style='color: #4AB819'>Firebase</a> account set up for your Zopping Account.",
  "how.to.install.abandoned.cart.extension":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>How to install the Abandoned Cart Extension on Zopping?</span>",
  "abandoned.cart.extension.setup.step1":
    "Navigate to <span style='color:black'>Settings > Extensions > Online Store</span> section. Here you will find the Abandoned Cart extension window as shown below.",
  "abandoned.cart.extension.setup.step2":
    "Click <span style='color:black'>Install</span> on the window, and add the extension to your website.",
  "abandoned.cart.extension.setup.step3":
    "After installing the Abandoned Cart extension, navigate to the <span style='color:black'>Extensions > Installed section</span>. You will find the same extension in this window. Click on the <span style='color:black'>Configure</span> option. ",
  "abandoned.cart.extension.setup.step4":
    "Once you land on the Abandoned Cart page, you will see a <span style='color:black'>Notification Types</span> options field. Select any/ all of the mediums <span style='color:black'>(Email, SMS or Push Notification)</span> through which you would like to nudge your customers to complete their purchase.",
  "abandoned.cart.extension.setup.step5":
    "In the field below, named <span style='color:black'>Notification Time</span>, you can define the minutes of inactivity (customer has not added/removed any product from the cart OR moved to checkout page) after which the system will send a notification to the user to resume shopping.",
  "abandoned.cart.extension.setup.step6":
    "Click on <span style='color:black'>Save</span> to save the changes.",
  "abandoned.cart.extension.setup.how.will.this.change.affect":
    "<span style='color: #0A0A0A; font-size: 22px; line-height:2rem'>How will this change affect my online store?</span>",
  "abandoned.cart.extension.setup.how.will.this.change.affect.point1":
    "When a user logs into their profile in your online store and adds products to their shopping cart. Lets say for some reason, the user has not made any changes to the cart or proceeded to checkout for a considerable amount of time.",
  "abandoned.cart.extension.setup.how.will.this.change.affect.point2":
    "In this scenario, according to the time limit configured as shown above, the user will receive a message/ notification to remind them to visit your online store and complete placing their order.",
  "abandoned.cart.extension.setup.how.will.this.change.affect.point3":
    "The user can directly access their account via the <span style='color:black'>Continue Shopping</span> button and complete their purchase. ",
  "abandoned.cart.extension.setup.what.happens.when.i.uninstall.this.extension.step1":
    "<ul style='padding-inline-start: 15px;margin:0px;'><li style='color: #0A0A0A;margin-bottom:3px;line-height:24px;font-size: 20px;' ><span style='color: #787872;font-size: 1rem;' >Your customers will no longer receive any notifications if they abandon the cart without making any purchase.</span></li></ul>",
};

export default messages;
